import { useLocation } from "react-router-dom";
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {
    TooltipComponent,
    LegendComponent,
} from 'echarts/components';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect, useBlockLayout,useResizeColumns } from 'react-table'
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useStateContext } from "../contexts/ContextProvider";
import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Loading from '../animations/Loading.json';
import { urlBase } from "../authConfig";
import { DashboardFilters, scrollbarWidth} from '../components';
import { FixedSizeList } from 'react-window';
import moment from 'moment'
import Triangulo from '../assets/Triangulo';
import Idioma from '../components/Idioma';
import fiscalWeeks from "../utils/fiscalWeeks";
// import fiscalWeeks from "../components/fiscalWeek";
import formatterDates3Letters from "../utils/datesRefactor";
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';
import { useNavigate } from "react-router-dom";

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
]);

const ChartSubPage = () => {
    const location = useLocation();
    const sign = location.state?.sign;
    const [catDic, setCatDic] = useState(location.state?.catDic);
    const [arr, setArr] = useState(location.state?.arrId);
    const { idClient, accessToken, idRS, setContextCategoriesIds, setContextBank, setContextAccount, setContextRSID, setContextFile, setContextShowState, setContextDateRange, language, numberFormat, userAccess, sessionID } = useStateContext();
    const { pieChartInfo, stackedBarInfo } = location.state || { pieChartInfo: [], stackedBarInfo: {} };
    const [info, setInfo] = useState();
    const colors = ['#DDDDF2', '#BBBBEA', '#9898EA', '#6F6FDD', '#4343D6', '#292987', '#1C1C4F', '#3D3DA3', '#6E5BAA', '#907FD6', '#BFB7ED', '#D6D3F2', '#9796AA', '#D3D3D3', '#C1C1DB'];
    const [requestParams, setRequestParams] = useState([null,null,null,null,null]);
    const [data, setData] = useState([]);
    const [numberDough, setNumberDough] = useState(location.state?.centerDough);
    const [divider, setDivider] = useState(location.state?.divider);
    const [center, setCenter] = useState(['60%', '50%']);
    const [radius, setRadius] = useState(['40%', '70%']);
    const [width, setWidth] = useState(1);
    const [fiscalWeekActivate, setFiscalWeekActivate] = useState(false);

    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        if (requestParams[1] != null) {
            getCategories()
        }
    }, [requestParams]);

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})
    }, []);

    useEffect(() => {
        if (requestParams[1] != null) {
            if(requestParams[3] === 2){
                setFiscalWeekActivate(true);
            }else {
                setFiscalWeekActivate(false);
            }
            getData()
        }
    },[catDic])

    useEffect(() => {
        if (requestParams[1] != null) {
            getInfoTransactions()
        }
    },[info])

    useEffect(() => {
        const currentScreen = window.innerWidth;
        if (currentScreen <= 1366 && currentScreen >= 1281) {
            setWidth(2);
        } else if(currentScreen <=1536 && currentScreen >= 1367) {
            setWidth(5);
        }else if (currentScreen <= 1280 && currentScreen >= 1125) {
            setWidth(3);
        } else if(currentScreen <= 1024 && currentScreen >= 911){
            setWidth(4);
        }else if(currentScreen <= 910 && currentScreen >= 770) {
            setWidth(6);
        }else {
            setWidth(1);
        }
      }, [])


    const getCategories = async() => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json');
  
        fetch(`${urlBase}/getCategories`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "clientID": idClient,
            "lowest": 0,
            showHidden: true,
            showCreditFinancing:true,
            forReport:true,
            logsInfo: {
                origin: sign === '+' ? 'Resumen - Entra de efectivo' : 'Resumen - Salida de efectivo',
                sessionID: sessionID
            }
          })
        }).then((response) => response.json())
          .then((aux1) => {
            let aux = aux1.categories
            let notUse = aux1.notUse
            let catDict = {}
  
            let flows = {"+": [], "-" : []};
  
            for(let i = 0;i<aux.length;i++){
              for(let j = 0; j < aux[i].subcategories.length; j++){
                catDict[aux[i].subcategories[j].idCategory] = {
                  "name": aux[i].subcategories[j].category,
                  "id": aux[i].subcategories[j].idCategory,
                  "isActive" : aux[i].subcategories[j].isActive === true,
                }
                for(let k = 0;k<aux[i].subcategories[j].subcategories.length;k++){
                  catDict[aux[i].subcategories[j].subcategories[k].idCategory] = {
                  "name": aux[i].subcategories[j].subcategories[k].category,
                  "id": aux[i].subcategories[j].subcategories[k].idCategory,
                  "isActive" : aux[i].subcategories[j].subcategories[k].isActive === true,
                  "idFather" : aux[i].subcategories[j].subcategories[k].idCategoryFather,
                  }
  
                  if(aux[i].orderNumber === 1 && requestParams[0] === 1){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(aux[i].orderNumber === 2 && requestParams[0] === 2){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(aux[i].orderNumber === 3 && requestParams[0] === 3){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
  
                  if(requestParams[0] === 0 && (aux[i].orderNumber === 1 || aux[i].orderNumber === 2 || aux[i].orderNumber === 3)){
                    flows[aux[i].subcategories[j].subcategories[k].flowType] = [...flows[aux[i].subcategories[j].subcategories[k].flowType], aux[i].subcategories[j].subcategories[k].idCategory]
                  }
                }
              }
            }
            setArr(sign === '+' ? flows["+"] : flows["-"]);

  
            setCatDic(catDict)
  
          });
      }

    const formatDatesTitle = ( date ) => {
        if( !date ) {
            return;
        }
  
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);
  
        return `${month}-${day}-${year}`;
    }

    const fechas = {
        'es': {
            'ene': 1,
            'feb': 2,
            'mar': 3,
            'abr': 4,
            'may': 5,
            'jun': 6,
            'jul': 7,
            'ago': 8,
            'sep': 9,
            'oct': 10,
            'nov': 11,
            'dic': 12
        },
        'en': {
            'jan': 1,
            'feb': 2,
            'mar': 3,
            'apr': 4,
            'may': 5,
            'jun': 6,
            'jul': 7,
            'aug': 8,
            'sep': 9,
            'oct': 10,
            'nov': 11,
            'dec': 12
        }
    };
    

    const dateFormatter = ( date ) => {
        let fechaDividida;
        if(language === 'es') {
            fechaDividida = date.split(" ");
            return `${parseInt(fechaDividida[1]) < 10 ? "" + fechaDividida[1] : fechaDividida[1]}/${fechas[language][fechaDividida[3]]}`
        }
        fechaDividida = date.split(" ");
        return `${fechas[language][fechaDividida[1]]}/ ${parseInt(fechaDividida[2]) < 10 ? "" + fechaDividida[2] : fechaDividida[2]}`
    }

    const formatearValores = (values) => {
        return values.map(value => new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            currencySign: numberFormat === 0 ? 'accounting' : 'standard',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value));
    };

    const getData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            startDate: requestParams[1],
            endDate: requestParams[2],
            groupType: requestParams[3],
            inOut: sign,
            topValues: "4",
            categoryGroup: requestParams[0],
            language: language,
            logsInfo: {
                origin: sign === '+' ? 'Resumen - Entra de efectivo' : 'Resumen - Salida de efectivo',
                sessionID: sessionID
            }
        };

        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4]) {
            body.idRS = requestParams[4].toString();
        }else {
            body.idRS = '';
        }

        const response = await fetch(`${urlBase}/chart/insideCharts`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        
        if(!response.ok) {
            throw new Error('Network response was no ok');
        }

        if(response.status === 200) {
            const aux = await response.json();
            let total = 0;
            for(let i = 0; i < aux.tops.length; i++) {
                total += aux.tops[i].value;
            }
            if(requestParams[3] === 2) {
                let nuevoWeekArray = [];
                for(let i = 0; i < aux.dayListLabel.length; i++){
                    let fullWeekInfo = aux.dayListLabel[i].split(" ");
                    let year = fullWeekInfo[1];
                    let numberWeek = fullWeekInfo[0].split("S")[1];
                    
                    nuevoWeekArray.push(dateFormatter(fiscalWeeks([year], [numberWeek], [language])));
                }
                aux.realDate = nuevoWeekArray;
            }

            let totalHover = [];

            let arrayLength = aux.realValuesStackedValues[0].data.length;

            for(let i = 0; i < arrayLength; i++) {
                totalHover.push(0);
            }

            for (let i = 0; i < aux.realValuesStackedValues.length; i++) {
                for (let j = 0; j < arrayLength; j++) {
                    totalHover[j] += aux.realValuesStackedValues[i].data[j];
                }
            }

            aux.totalHover = totalHover;

            setDivider(aux.divider);

            total = Math.round(total * 100) / 100;
            
            setNumberDough(total);
            setInfo(aux);
        }
    }

    const getInfoTransactions = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            startDate: formatDatesTitle(requestParams[1]),
            endDate: formatDatesTitle(requestParams[2]),
            showState: "classified",
            logsInfo: {
                origin: sign === '+' ? 'Resumen - Entra de efectivo' : 'Resumen - Salida de efectivo',
                sessionID: sessionID
            }
        };

        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4] && requestParams[4].length > 0) {
            body.rsID = requestParams[4];
        }

        body.categories = arr;

        fetch(`${urlBase}/getLineageTransactions`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            let newData = []
            let newExcelData = []
            for(let i = 0;i<aux.length;i++){
                newData[i] = {
                    'bank':aux[i].bank,
                    'date':aux[i].date_d.slice(0,10),
                    'id':aux[i].idTransaction,
                    'context':aux[i].context,
                    'reference':aux[i].references_c,
                    'inflow':aux[i].inflow ,
                    'outflow':aux[i].outflow,
                    'category':catDic[catDic[aux[i].idCategory]?.idFather]?.name || '',
                    'subcategory':catDic[aux[i].idCategory]?.name || '',
                    'exchange':aux[i].exchange || aux[i].defaultFX ,
                    "updated":null,
                    'accountType':aux[i].accountType==='D'?'Débito':aux[i].accountType==='C' ? 'Crédito' :'NA',
                    'signature':aux[i].transactionSignature,
                    'notes': aux[i].notes,
                    'idAccount':aux[i].accountAlias || aux[i].accountNumber,
                    'currency': aux[i].currency,
                    'rsName':aux[i].rsName,
                    'extraText': aux[i].extraText
                }
                newExcelData[i] = {
                    'Empresa':aux[i].rsName,
                    'Banco':aux[i].bank,
                    'Cuenta':aux[i].accountAlias || aux[i].accountNumber,
                    'Fecha':aux[i].date_d.slice(0,10),
                    'Concepto':aux[i].context,
                    'Abono':aux[i].inflow ,
                    'Cargo':aux[i].outflow,
                    'Categoría':catDic[catDic[aux[i].idCategory]?.idFather]?.name || '',
                    'Subcategoría':catDic[aux[i].idCategory]?.name || '',
                    'Moneda': aux[i].currency,
                    'FX':aux[i].exchange || aux[i].defaultFX ,
                    'Notas': aux[i].notes,
                    'Información adicional':''
                }
                let jsonAux = JSON.parse(aux[i].extraText || "{}")
                let jsonKeys = Object.keys(jsonAux)
                for(let j=0;j<jsonKeys.length;j++){
                    newExcelData[i]['Información adicional'] = `${newExcelData[i]['Información adicional']}${jsonKeys[j]}:${jsonAux[jsonKeys[j]]}${jsonKeys.length!==j+1 ? '\n':''}`
                }
            }

            setData(newData)
            //setExcelLineageData(newExcelData)
        });
    }

    
    const genLineageCell = (cell,row) => {
		if(cell.column.id==='exchange'){
			return (
				<div className='text-right xs:text-[10px]  relative tabular-nums w-[100%] lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code',
				  minimumSignificantDigits:'4'}).slice(4) : cell.value==0 ? '--' : ''}</span>
				</div>
			)
		}else if(cell.column.id==='inflow'){
			return (
				<div className='text-right xs:text-[10px]  ml-4 relative tabular-nums w-[100%] pr-4 lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code'}).slice(4) : '--'}</span>
				</div>
			)
		}else if(cell.column.id==='outflow'){
			return (
				<div className='text-right xs:text-[10px]  ml-4 relative tabular-nums w-[100%] pr-4 lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>
                <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    currencySign: "accounting",
                    currencyDisplay: 'code'
                    }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
				</div>
			)
		}else if(cell.column.id==='date'){
			return <div className='w-[100%] xs:text-[10px]  ml-3 lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>{language === 'es' ? moment(cell.value).locale(language).format('D/MMM/YYYY').replace('.','') : moment(cell.value).locale(language).format('MMM/D/YYYY').replace('.','') }</div> 
		}else if(cell.column.id === 'extraText'){
			if(row.original.extraText){
			  let json = JSON.parse(row.original.extraText)
			  let aux = Object.keys(json) 
			  let auxText = ``
			  for(let i=0;i<aux.length;i++){
				auxText =auxText+ `${aux[i]} : ${json[aux[i]]},\n`
			  } 
			  return <div className='w-[100%] xs:text-[10px]  ml-4 text-left truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={auxText}>{auxText}</div>
			}else{
			  return <div className=' w-[100%] xs:text-[10px]  ml-4 text-left lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]'>{cell.value}</div>    
			} 
			
		}else if(cell.column.id === 'bank'){
            return (
				<div className=' w-[100%] xs:text-[10px] mr-10 truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
        }else if(cell.column.id === 'idAccount') {
            return (
				<div className=' w-[100%] xs:text-[10px]  mr-10 truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
        }else if(cell.column.id === 'subcategory') {
            return (
				<div className=' w-[100%] xs:text-[10px]  ml-1 mr-10 truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
        }else if(cell.column.id === 'currency') {
            return (
				<div className=' w-[100%] xs:text-[10px] ml-1 mr-10 truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
        }else{
			return (
				<div className=' w-[100%] xs:text-[10px] ml-4 mr-10 truncate lg:text-h13-2xl md:text-h13-2xl sm:text-[8px]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
		}
		
	}

	const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
	const defaultColumn = React.useMemo(
        () => ({
        minWidth: 10, // minWidth is only used as a limit for resizing
        width: 120, // width is used for both the flex-basis and flex-grow
        maxWidth: 1800, // maxWidth is only used as a limit for resizing
        }),
        []
    )

    const handlerDatesTransactions = ( date )  => {
        if(!date) {
            return;
        }

        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 9);

        return new Date(year, parseInt(month, 10) - 1,day);
    }
	

	const columns = React.useMemo(
        () => [
            ...(requestParams[4] && requestParams[4].length > 0
                ? [{
                        Header: Idioma.transacciones.configuracion.empresa[language],
                        accessor: 'rsName',
                        className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold'
                    }]
                : [] ),
			{
				Header: Idioma.transacciones.configuracion.banco[language],
				accessor: 'bank',
                className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
                width: width === 4 ? document.documentElement.clientHeight * 0.1 : width === 6 ? document.documentElement.clientHeight * 0.1 : document.documentElement.clientHeight * 0.15
			},
			{
				Header: Idioma.transacciones.anadirTransaccionModal.cuenta[language],
				accessor: 'idAccount',
                width: width ===4 ? document.documentElement.clientHeight * 0.1 : document.documentElement.clientHeight*.15,
                className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold'
			},
			{
				Header: Idioma.transacciones.configuracion.fecha[language],
				accessor: 'date',
				sortType:'basic',
                width: width === 3 ? document.documentElement.clientHeight * 0.1 : width === 4 ? document.documentElement.clientHeight * 0.10 : width === 6 ? document.documentElement.clientHeight * 0.1 : document.documentElement.clientHeight * 0.3,
				className: 'text-left lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold ml-4',
			},
			{
				Header: Idioma.transacciones.configuracion.concepto[language],
				accessor: 'context',
				className: 'text-left lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
				width: width === 1 ? 350 : width === 2 ? 100 : width === 5 ? 40 : width === 6 ? 40 : 50
			},
            ...(sign === '+' ? 
                [{
                    Header: Idioma.transacciones.configuracion.abono[language],
                    className: 'text-right lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
                    filter: 'between',
                    width: width === 4 ? document.documentElement.clientHeight * 0.15 : width === 6 ? document.documentElement.clientHeight * 0.1 : document.documentElement.clientHeight * 0.2,
                    accessor: 'inflow',
                }]
                :
                [{
                    Header: Idioma.transacciones.configuracion.cargo[language],
                    className: 'text-right lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold',
                    width: width === 4 ? document.documentElement.clientHeight * 0.15 : width === 6 ? document.documentElement.clientHeight * 0.1 : document.documentElement.clientHeight * 0.2,
                    filter: 'between',
                    accessor: 'outflow',
                }]
            ),
            {
                Header: Idioma.transacciones.configuracion.categoria[language],
                accessor: 'category',
                width: width === 5 ? document.documentElement.clientHeight*.25 : width === 2 ? document.documentElement.clientHeight * 0.2 : width === 3 ? document.documentElement.clientHeight * 0.19 : width === 4 ? document.documentElement.clientHeight * 0.12 : width === 6 ? document.documentElement.clientHeight * 0.16 : document.documentElement.clientHeight * 0.25,
                className: 'lg:text-h13-2xl pl-2 md:text-h13-2xl sm:text-[9px] sm:font-bold'
            },
            {
                Header: Idioma.transacciones.configuracion.subcategoria[language],
                accessor: 'subcategory',
                width: width === 4 ? document.documentElement.clientHeight * 0.15 : width === 6 ? document.documentElement.clientHeight * 0.1: document.documentElement.clientHeight*.20,
            },{
				Header: Idioma.transacciones.configuracion.moneda[language],
				accessor: 'currency',
                className: 'lg:text-h13-2xl md:text-h13-2xl sm:text-[9px] sm:font-bold'
			},
        ],
        [requestParams]
      )

    const redirectLineage = () => {
		setContextBank({name:'Banco'})
		setContextAccount({name: 'Cuenta de banco'})
		if(requestParams[4] !== null && !idRS){
			setContextRSID(requestParams[4])
		}else{
			setContextRSID([])
		}
        setContextCategoriesIds(arr);
		setContextFile({name:'Seleccionar Archivo',percentage:''})
		setContextShowState('classified')
		setContextDateRange([handlerDatesTransactions(requestParams[1]), handlerDatesTransactions(requestParams[2])])
		navigate('/classification')
	}

	const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        allColumns,
        state,
        setSortBy,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
          autoResetPage: false,
          defaultColumn, // Be sure to pass the defaultColumn option
          autoResetSortBy:false
        },
        useSortBy,
        useBlockLayout,
      )

	  const RenderRow = React.useCallback(
        (rows) => ({ index, style }) => {
          const row = rows[index];
          prepareRow(row);
          return (
            <div
            {...row.getRowProps({
              style
            })}
            >
              {row.cells.map((cell) => {
                return (
                  <div><td {...cell.getCellProps()} className='w-[100%] '>{genLineageCell(cell,row)}</td></div>
                );
              })}
            </div>
          );
        },
        [prepareRow,data]
      );

    function adjustLegendSettings() {
    const width = window.innerWidth;

    const commonSetting = {
        overflow: 'truncate',
        ellipsis: '...',
        width: 100,
        tooltip: {
            show: true,
            formatter: (name) => {
                return `${name}`;
            }
        }
    }

    if (width < 640) { // xs
        return {
            ...commonSetting,
            fontSize: 10,
        }
    } else if (width >= 640 && width < 768) { // sm
        return {
            ...commonSetting,
            fontSize: 12,
        }
    } else if (width >= 768 && width < 1024) { // md
        return {
            ...commonSetting,
            fontSize: 14,
        }
    } else if (width >= 1024 && width < 1281) {
        return {
            ...commonSetting,
            fontSize: 14,
        }
    }else  {
        return {fontSize: 14}
    }
    }

    useEffect(() => {
        const handleResize = () => {
            const currentScreen = window.innerWidth;
            if (currentScreen <= 1366 && currentScreen >= 1281) {
                setCenter(['60%', '50%']);
                setRadius(['30%', '50%']);
            } else if (currentScreen <= 1280 && currentScreen >= 1125) {
                setCenter(['60%', '50%']);
                setRadius(['25%', '40%']);
            } else if(currentScreen <= 1024 && currentScreen >= 901){
                setCenter(['60%', '50%']);
                setRadius(['50%', '85%']);
            }else {
                setCenter(['60%', '50%']);
                setRadius(['40%', '70%']);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    let xAxisData = info && requestParams[3] === 2 ? info.realDate : (info ? info.dayListLabel : []);

    let chartOption = info ? {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function(params) {
                let index = params[0].dataIndex;
                if (typeof index !== 'number') {
                    index = info.dayListLabel.findIndex(month => month.startsWith(index));
                }
            
                let monthLabel = (typeof index === 'number' && info.dayListLabel[index]) ? info.dayListLabel[index] : 'Índice no válido';
                let result;
                if(requestParams[3] === 2) {
                    let newValues = monthLabel.split(" ");
                    let week = newValues[0].split('S')[1];
                    result = formatterDates3Letters(fiscalWeeks(newValues[1], week, language), language) + " " + newValues[1] + '<br/>';
                }else {
                    result = `<p class="font-bold">${monthLabel}</p> `;
                }
                result += `<p class="font-bold">Total: ${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD', 
                        currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                        minimumFractionDigits: 2, 
                        maximumFractionDigits: 2
                    }).format(info.totalHover[index])} </p>`

                info.realValuesStackedValues.forEach(series => {
                    let value = series.data[index]; // Obtén el valor de la primera posición (o el índice seleccionado)

                    let amount = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD', 
                        currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                        minimumFractionDigits: 2, 
                        maximumFractionDigits: 2
                    }).format(value);
                    
                    result += `${series.name}: ${amount}<br/>`;
                });
                return result;
            }
        },
        legend: {
            show: false,
            data: info.topsStacked.map(item => item.name),
            selectedMode: false
        },
        grid: {
            top: '5%', 
            left: '10%', // Ajusta este valor según el margen izquierdo que necesites
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,
                axisLabel: {
                    interval: 4
                },
                axisTick: {
                    show: true
                },
            }
        ],
        yAxis: {
            name: '',
            splitLine: { show: true}
        },
        series: info.topsStacked
    } : {};
    

    let optionDoughnut = info ? {
        color: colors,
        tooltip: {
            trigger: 'item',
            formatter: (params) => {
                let index = params.dataIndex
                const formattedValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(info.newTops[index].value);
                const percentage = params.percent.toFixed(2);
                return `${params.name} <br/> ${formattedValue} <br/> ${percentage}%`
            },
        },
        legend: {
            top: 'center',
            left: 'left', 
            padding: -1,
            selectedMode: false,
            orient: 'vertical',
            itemGap: 25,
            textStyle: adjustLegendSettings(),
            icon: 'square'
        
        },
        series: [
            {
                name: info.tops && info.tops.length > 0 ? 'Acess From' : '',
                type: 'pie',
                center: center,
                radius: radius,
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2,
                },
                label: {
                    normal: {
                        show: true,
                        position: 'center',
                        formatter: () => {
                            const formattedNumber = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 1,
                                currencySign: numberFormat === 0 ? 'accounting' : 'standard'
                            }).format(numberDough);
                            return `{bold|${formattedNumber}} {normal|${divider}}`;
                        },
                        rich: {
                            bold: {
                                fontSize: 25,
                                fontWeight: 'bold'
                            },
                            normal: {
                                fontSize: 25,
                                fontWeight: 'normal'
                            }
                        },
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false,
                },
                data: info.tops,
            },
        ],
    } : {};
    
    

    return (
        <div className="w-full flex flex-col pl-[5%] xs:pl-0">
            {(!info || !catDic || !data) &&
                <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-neutral-5'></div>
                    <div className='w-full h-full flex justify-center items-center pr-[15%]'>
                        <Player
                        className='w-[20%]'
                        autoplay
                        loop
                        src={Loading}
                        >
                        </Player>
                    </div>
                </div>
            }
            <DashboardFilters requestParams={requestParams} setRequestParams={setRequestParams} innerGraph={true} />
            {sign === '+' ?
                <h3 className="text-h8-2xl lg:text-h11-2xl md:text-h12-2xl sm:text-h9-2xl">{Idioma.resumen.graficasArriba.entradaEfectivoConcepto[language]}</h3>
            : 
                <h3 className="text-h8-2xl lg:text-h11-2xl md:text-h12-2xl sm:text-h9-2xl">{Idioma.resumen.graficasArriba.salidaEfectivoConceptos[language]}</h3>
            }
            <div className="flex flex-wrap xs:items-center xs:flex-col xxs:flex-col mobileL:flex-col mobileM:flex-col mobileS:flex-col mt-[10px] sm:flex-col sm:items-center sm:justify-center">
                <div className="w-1/2 xs:pl-[5%] xs:w-full sm:w-full sm:items-center sm:justify-center sm:ml-10">
                    <ReactEChartsCore
                        echarts={echarts}
                        option={optionDoughnut}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                        style={{ height: '400px', width: '100%' }}
                    />
                </div>
                <div className="w-1/2 xs:w-full sm:mr-12 sm:w-full sm:items-center sm:justify-center">
                <ReactEChartsCore 
                            echarts={echarts}
                            option={chartOption}
                            notMerge={true}
                            lazyUpdate={true}
                            theme="theme_name"
                            style={{ height: '400px', width: '100%' }}
                />
                </div>
            </div>
            {idClient && userAccess &&
                <>
                    {userAccess.access2 && 
                        <>   
                            <div>
                                <div className="w-[95%] xs:pl-[5%] flex justify-between items-center mb-5">
                                    <h2 className="text-xl sm:text-center text-h6-2xl lg:text-h11-2xl md:text-h12-2xl sm:text-h9-2xl">{Idioma.resumen.graficasArriba.detalleTransacciones[language]}</h2>
                                    <button className="bg-light-purple-3 text-3xl p-2 text-white rounded-button text-p5-2xl lg:w-[210px] lg:text-p6-2xl md:w-[180px] md:text-p8-2xl sm:w-[180px] sm:h-[35px] w-[250px] h-[50px] sm:text-p8-2xl" onClick={() => redirectLineage()}>{Idioma.resumen.graficasArriba.abrirTransacciones[language]}</button>
                                </div>
                                <div className="relative xs:pl-[5%] overflow-x-auto thin-scrollbar">
                                    <table {...getTableProps()} className="table">
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
                                                {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()} className={column.className + '  '}>
                                                    {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
                                                    {
                                                    <p className={`flex pr-2 text-[12px] 2xl:text-[16px] items-center cursor-pointer whitespace-nowrap ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':''}`} onClick={()=>{
                                                        const desc =
                                                        column.isSortedDesc === true ?
                                                        undefined :
                                                        column.isSortedDesc === false ?
                                                        true :
                                                        false ;
                                                        if(desc===undefined){
                                                        setSortBy([/*...sortees*/])
                                                        }else if(desc===true){
                                                        setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
                                                        }else{
                                                        setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
                                                        }
                                                        
                                                        
                                                    }} >{column.Header}
                                                    <span className='ml-1'>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                        ? <Triangulo/>
                                                        : <div className='rotate-180'><Triangulo/></div>
                                                        : <></>}
                                                        
                                                        
                                                    </span></p>
                                                    }
                                                </th>
                                                ))}
                                            </tr>
                                            ))}
                                        </thead>
                
                                        <tbody {...getTableBodyProps()} className='text-p9-2xl'>
                                            <FixedSizeList
                                            height={document.documentElement.clientHeight*.30}
                                            itemCount={rows.length}
                                            itemSize={35}
                                            width={totalColumnsWidth+scrollBarSize}
                                            >
                                            {RenderRow(rows)}
                                            </FixedSizeList>
                                        </tbody>
                                    </table>
                                </div>
                
                            </div>
                        </> 
                    }
                </>
            }

        </div>
    );
};

export default ChartSubPage;
