import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { PencilSquareIcon, TrashIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import XMark from "../assets/XMark";
import moment from "moment";
import Idioma from '../components/Idioma';
import { DinamicDropdown, Dropdown, SyncfyWidget } from '../components';
import Loading from '../animations/Loading.json'
import 'moment/locale/es';
import DatePicker from 'react-datepicker';
import { Player } from "@lottiefiles/react-lottie-player";
import { msalConfig, urlBase } from "../authConfig";
import MessageToast from "../components/MessageToast";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import Pencil from '../assets/Pencil';
import { getToken } from '../fetch';
import InfoI from "../components/infoI";
import HoverModal from "../components/HoverModal";

import SearchLogo from "../assets/SearchLogo";
moment.locale('es')

const Saldos = () => {
    const {idClient, accessToken, idRS, clientRSOptions, language, mainCurrency, numberFormat, sessionID, setToken, userAccess} = useStateContext();
    const [state, setState] = useState({
        infoDebit: [],
        infoCredit: [],
        infoLinked: [],
        currentEx: undefined,
        date: '',
    });
    const [openAgregar, setOpenAgregar] = useState(false);
    const [errorIndex, setErrorIndex] = useState(null);
    const [opcionBanco,setOpcionBanco] = useState({name: Idioma.cuentasYSaldos.banco[language]});
    const [opcionRS, setOpcionesRS] = useState({name: Idioma.cuentasYSaldos.razonSocial[language]});
    const [opcionesBancos,setOpcionesBancos] = useState([]);
    const [account, setAccount] = useState('');
    const [alias, setAlias] = useState('');
    const [isActivate, setIsActivate] = useState();
    const [moneda,setMoneda] = useState({name: Idioma.cuentasYSaldos.moneda[language] });
    const [monedas, setMonedas] = useState([{name:'MXN'},{name:'USD'}, {name: 'EUR'}]);
    const [initialValue, setInitialValue] = useState(0);
    const [newDate, setnewDate] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [startDateCutoff, setStartDateCutoff] = useState({name: 15, id: 15});
    const [bancos,setBancos]=useState([]);
    const [bankType, setBankType] = useState( {name: Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language] });
    const [typeBank, setTypeBank] = useState([{name: Idioma.cuentasYSaldos.debito[language]}, {name: Idioma.cuentasYSaldos.credito[language]}]);
    const [ingresosType, setIngresosType] = useState();
    const [egresoType, setEgresoType] = useState();
    const [modalAddCount, setModalAddCount] = useState('');
    const [openModal, isOpenModal] = useState(false);
    const [reloadData, setReloadData] = useState(true);
    const [banco,setBanco] = useState({name: Idioma.cuentasYSaldos.banco[language]});
    const [bankTypeReal, setBankTypeReal] = useState();
    const [bankTypeRealModify, setBanktypeRealModify] = useState();
    const [bankTypeModify, setBankTypeModify] = useState();
    const [accountData,setAccountData] = useState([]);
    const [ accountDataMXN, setAccountDataMXN] = useState([]);
    const [ accountDataUSD, setAccountDataUSD] = useState([]);
    const [ accountDataEUR, setAccountDataEUR ] = useState([]);
    const [data,setData] = useState([]);
    const [accountTableActive,setAccountTableActive] = useState(false);
    const [optionsModal, setOptionsModal] = useState();
    const [opcionBank,setOpcionBank] = useState(null);
    const [defaultAccount, setDefaultAccount] = useState('');
    const [validDuplicateNames, setValidDuplicateNames] = useState([]);
    const [monedaUpload,setMonedaUpload] = useState({});
    const [accountAlias, setAccountAlias] = useState();
    const [currentValue, setCurrentValue] = useState();
    const [dateUpload, setDateUpload] = useState();
    const [newDateUpload, setNewDateUpload] = useState();
    const [idUpdate, setIdUpdate] = useState();
    const [inflowCategories,setInflowCategories] = useState([]);
    const [inflowName, setInflowName] = useState();
    const [outflowCategories,setOutflowCategories] = useState([]); 
    const [outflowName, setOutFlowName] = useState();
    const [ DeleteConfirmation, setDeleteConfirmation] = useState(false);
    const [itemToDelete, setItemToDelete] = useState();
    const [categories,setCategories] = useState([]);
    const [subcategories,setSubcategories] = useState([])
    const [flagErrorMessage, setFlagErrorMessage] = useState(false);
    const [NotClassified, setNotClassified] = useState([]);
    const [hover, setHover] = useState(null);
    const [nothing, setNothing] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [accountDuplicated, setAccountDuplicated] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [canDelete, setCanDelete] = useState(1);
    const [typeOfAccount, setTypeAccount] = useState();
    const [idRString, setIdRString] = useState('');
    const [clientRSMap, setClientRSMap] = useState({});
    const [dataModal, setDataModal] = useState([]);
    const [rsModify, setRsModify] = useState();
    const [rs, setRs] = useState([]); 
    const [dateFilter, setDateFilter] = useState(new Date());
    const [loadCreateAc, setLoadCreateAc] = useState(false);
    const [loadUpdateAc, setLoadUpdateAc] = useState(false);
    const [bankTitle, setBankTitle] = useState();
    const [accountId, setAccountId] = useState();
    const [editPostModal, setEditPostModal] = useState(false);
    const [closingDay, setClosingDay] = useState()
    const [version, setVersion] = useState(1);
    const [activeTab, setActiveTab] = useState('debit');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItemsCredit, setTotalItemsCredit] = useState(0);
    const [totalItemsDebit, setTotalItemsDebit] = useState(0);
    const [totalPagesCredit, setTotalPagesCredit] = useState(1);
    const [totalPagesDebit, setTotalPagesDebit] = useState(1);
    const itemsPerPage = 10;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const [syncfyToken, setSyncfyToken] = useState(null);
    const [syncfyAccount, setSyncfyAccount] = useState({'name': Idioma.Syncfy.noVinculada[language]});
    const [syncfyAccountOptions, setSyncfyAccountOptions] = useState([]);
    const [allSyncfyAccounts, setAllSyncfyAccounts] = useState([]);
    const [syncfyOrganizations, setSyncfyOrganizations] = useState({});
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [SyncfyDeleteConfirmation, setSyncfyDeleteConfirmation] = useState(false)
    const [syncfyWarning, setSyncfyWarning] = useState(false)
    const [unlinkedAccounts ,setUnlinkedAccounts] = useState(0)
    const [disabledButtonModify, setDisableButtonModify] = useState(true);
    const [currencyEdit, setCurrencyEdit] = useState('');
    const [modalWarning, setModalWarning] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [flagErrorMessageUpdate, setFlagErrorMessageUpdate] = useState(false);

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})
        setMoneda({ name: Idioma.cuentasYSaldos.moneda[language]});
        if(userAccess.access7 && !idRS){
            authSyncfy()
        }
    }, []);

    useEffect(() => {
        if(disabledButtonModify || !verifyDeuplicates(defaultAccount, {name: activeTab === 'debit' ? Idioma.cuentasYSaldos.debito[language] : Idioma.cuentasYSaldos.credito[language]})){
            setFlagErrorMessageUpdate(true);
        }else {
            setFlagErrorMessageUpdate(false);
        }
    }, [defaultAccount, disabledButtonModify, activeTab]);

    useEffect(() => {
        const fetchData = async() => {
            try{
                // await dateRequ(false, '', false);
                getDataBanks();
                getExchange()
                getCategories();
                getNotClassified();
                getRazonesSociales();
                getData()
            }catch(e){
                console.error(e);
            }
        }
        if(reloadData){
            fetchData();
            setReloadData(false);
        }
    }, [reloadData]);

    useEffect(() => {
       if(activeTab==='linked'){
            getSyncfyAccounts()
       }
    }, [activeTab]);

    useEffect(() => {
        setTimeout(() => {
            isOpenModal(false);
            setOpenAgregar(false);
            resetValues();
        }, 1000);

        if(openAgregar === false) {
            setOpcionBanco({name: Idioma.cuentasYSaldos.banco[language]});
            setAccount('');
            setAccountAlias('')
            setMoneda({name: Idioma.cuentasYSaldos.moneda[language]});
            setInitialValue(0);
            setStartDate(new Date());
            setBankType( {name: Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language]});
            setBankTypeReal('');
            setAlias('');
        }
    }, [openModal])

    useEffect(() => {
        const isDebit = bankType.name === Idioma.cuentasYSaldos.debito[language];
        const isValidAccount = (opcionBanco !== Idioma.cuentasYSaldos.banco[language]) && (account !== '') && (alias !== '') && (moneda.name !== Idioma.cuentasYSaldos.moneda[language]) && (bankType.name !== Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language]);
        const isValidRS = opcionRS.name !== Idioma.cuentasYSaldos.razonSocial[language];
        const isValidInitialValue = (initialValue >= 0 && bankTypeReal !== 'C');

        if (rs.length > 0 && !idRS) {
            if (isDebit) {
                setDisableButton(!(isValidAccount && isValidRS && isValidInitialValue));
            } else {
                setDisableButton(!(isValidAccount && isValidRS));
            }
        } else {
            if (isDebit) {
                setDisableButton(!(isValidAccount && isValidInitialValue));
            } else {
                setDisableButton(!isValidAccount);
            }
        }
    }, [opcionBanco, account, moneda, initialValue, startDate, bankType, alias, opcionRS, startDateCutoff, ingresosType, egresoType]);

    useEffect(() => {
        if(flagErrorMessage) {
            setTimeout(() => {
                setFlagErrorMessage(false);
            }, 10000);
        }
    }, [flagErrorMessage]);

    const resetValues = () => {
        setOpcionBanco({name: Idioma.cuentasYSaldos.banco[language]});
        setAccount('');
        setAccountAlias('')
        setMoneda({name: Idioma.cuentasYSaldos.moneda[language]});
        setInitialValue(0);
        setStartDate(new Date());
        setBankType( {name: Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language]});
        setBankTypeReal('');
        setAlias('');
        setOpcionesRS({name: Idioma.cuentasYSaldos.razonSocial[language]})
        setRsModify('');
        setStartDateCutoff({name: 15, id: 15})
        setCurrentOrganization(null)
        setSyncfyAccount({'name':Idioma.Syncfy.noVinculada[language]})
    }

    useEffect(() => {
        if(!reloadData){
            getData();
        }
    }, [dateFilter]);

    useEffect(() => {
        let data = [];
        for( let i = 0; i < clientRSOptions.length; i++ ) {
            let auxCheck = []
            auxCheck = idRString.split(',').map(Number)
            if(idClient === clientRSOptions[i].idClient) {
                if(auxCheck.includes(clientRSOptions[i].idRS)){
                    data.push({name: clientRSOptions[i].rsName, id: clientRSOptions[i].idRS})
                }else{
                    data.push({name: clientRSOptions[i].rsName, id: clientRSOptions[i].idRS})
                }
            }
        }

        let aux = {}
        for(let i=0;i<clientRSOptions.length;i++){
            if(!aux[clientRSOptions[i].idClient]){
                aux[clientRSOptions[i].idClient] = []
            }
            aux[clientRSOptions[i].idClient] = [...aux[clientRSOptions[i].idClient],clientRSOptions[i]]
        }
        setClientRSMap(aux);
        setDataModal(data);
    }, []);

    useEffect(() => {
        if(newDate === null || newDate === undefined){
            let dateNow = new Date();
            const year = dateNow.getFullYear();
            const month = (dateNow.getMonth() + 1).toString().padStart(2, '0');
            const day = dateNow.getDate().toString().padStart(2, '0');
            let aux = `${year}-${month}-${day}`;
            setnewDate(aux);
        }
    }, []);

    useEffect(() => {
        if(syncfyToken && userAccess.access7 && !idRS){
            getSyncfyAccounts()
        }
    }, [syncfyToken])

    useEffect(() => {
        if(currentOrganization){
            let aux = []
            allSyncfyAccounts.forEach(account => {
                if(account.org_id == currentOrganization){
                    aux.push(account)
                }
            })
            if(aux.length==0){
                setSyncfyAccount({name: Idioma.Syncfy.noCuentas[language]})
            }else{
                aux.unshift({name: Idioma.Syncfy.noVinculada[language]})
            }
            setSyncfyAccountOptions(aux)
        }else{
            setSyncfyAccountOptions(allSyncfyAccounts.slice())
        }
    }, [currentOrganization])

    const authSyncfy = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/authUser`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        })
        .then(response => response.json())
        .then(aux => {
            if(aux.token){
                setSyncfyToken(aux.token);
            }
        }).catch(err => console.log(err));
    }

    const getSyncfyAccounts = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/getAccounts`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'token': syncfyToken,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        })
        .then(response => response.json())
        .then(aux => {
            let aux2=[]
            let aux3=[]
            aux.forEach(account => {
                aux2.push({'name': account.name, 'id': account.id_account, 'org_id': account.id_site_organization})
                let tmp = {'name': account.name, 'id': account.id_account, 'org_id': account.id_site_organization, 'last_sync': account.dt_refresh}
                if(account.accountAlias){
                    tmp.accountAlias=account.accountAlias
                }else{
                    tmp.accountAlias=Idioma.Syncfy.noVinculada[language]
                }
                if(account.accountNumber){
                    tmp.accountNumber=account.accountNumber
                }else{
                    tmp.accountNumber=Idioma.Syncfy.noVinculada[language]
                }
                aux3.push(tmp)
            })
            if(aux2.length>1){
                aux2.unshift({"name": Idioma.Syncfy.noVinculada[language]})
            }
            if(aux2.length==0){
                aux2.unshift({"name": Idioma.Syncfy.noCuentas[language]})
            }
            setState(prevState => ({
                ...prevState,
                infoLinked: aux3,
            }));
            setSyncfyAccount(aux2[0])
            setSyncfyAccountOptions(aux2)
            setAllSyncfyAccounts(aux2)
        }).catch(err => console.log(err));
    }

    const deleteSyncfyAccount = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/sync/deleteAccount`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'token': syncfyToken,
                'syncfyAccountID':syncfyAccount.id,
                'syncfyOrganizationID':syncfyAccount.org_id,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        })
        .then(response => response.json())
        .then(aux => {
            setSyncfyDeleteConfirmation(false)
            getSyncfyAccounts()
            setSyncfyWarning(true)
            setUnlinkedAccounts(aux.unlinked)
        }).catch(err => console.log(err));
    }

    const formatDatePicker = ( date ) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return month >= 10 && day >= 10 ? `${year}${month}${day}` : month >= 10 && day < 10 ? `${year}${month}0${day}` : month < 10 && day >= 10 ? `${year}0${month}${day}` : `${year}0${month}0${day}`;
    }

    const setDatesPicker = ( date ) => {
        setStartDate( date );
        setReloadData(true)
    }

    const getNotClassified = async() => {
        let header = new Headers();
        
        const bearer = `Bearer ${accessToken}`;
        header.append("Authorization", bearer);
        header.append("Content-Type",'application/json');

        fetch(`${urlBase}/getNotClassified`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                "clientID": idClient,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        })
        .then(res => res.json())
        .then((aux) => {
            setNotClassified(aux);
        });
    }

    const getDataBanks = async () => {

        console.log('Hello there')
        

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        fetch(`${urlBase}/getAccounts`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "clientID": idClient, 
            logsInfo: {
                origin: 'Cuentas y Saldos',
                sessionID: sessionID
            }
        })
        })
          .then((response) => response.json())
          .then((aux) => {
            let newData=[]
            for (const property in aux) {
                newData[property] = aux[property]
            }
            let aux2 = Object.getOwnPropertyNames(aux);
            let aux3 = []
            aux3[0] = {name: Idioma.subirTransacciones.dropBanco[language]}
            for(let i=1;i<aux2.length+1;i++){
                aux3[i] = {'name':aux2[i-1]}
            }
            setBancos(aux3)
            setBanco(aux3[0])
            let aux4 = [];
            let count= 0;
            for(let i=0;i<aux2.length;i++){
                for(let j=0;j<newData[aux2[i]].accounts.length;j++){
                    aux4[count] = {
                        id:newData[aux2[i]].accounts[j].accountID,
                        bank:newData[aux2[i]].bank,
                        account:newData[aux2[i]].accounts[j].accountNumber,
                        currency:newData[aux2[i]].accounts[j].currency,
                        initialValue:newData[aux2[i]].accounts[j].initialValue.toLocaleString('en-us'),
                        initialDate:newData[aux2[i]].accounts[j].initialDate.slice(0,10),
                        rs: newData[aux2[i]].accounts[j].rsID,
                        total: newData[aux2[i]].accounts[j].total,
                        closingDay: newData[aux2[i]].accounts[j].closingDay,
                        idSyncfy: newData[aux2[i]].accounts[j].idSyncfy
                    }
                    count++;
                }
            }
            setAccountData(aux4);
            let auxUSD = [];
            let auxMXN = [];
            let auxEUR = [];

            for(let i = 0; i < aux4.length; i++) {
                if(aux4[i].currency === 'USD'){
                    auxUSD.push(aux4[i]);
                }else if(aux4[i].currency === 'MXN') {
                    auxMXN.push(aux4[i]);
                }else {
                    auxEUR.push(aux4[i]);
                }
            }

            setAccountDataMXN(auxMXN);
            setAccountDataUSD(auxUSD);
            setAccountDataEUR(auxEUR);


            setData(newData)
            if(aux3.length>1){
                setAccountTableActive(true)
            } 
        });
        
        fetch(`${urlBase}/getBanks`,{
          method: 'GET',
          headers: headers,
        })
          .then((response) => response.json())
          .then((aux) => {
            let aux2=[]
            let aux3={}
            for(let i=0;i<aux.length;i++){
                aux2[i] = {
                    'name':aux[i].bank,
                    'id':aux[i].idBank
                }
                aux3[aux[i].bank]=aux[i].idSyncfy
                aux3[aux[i].idSyncfy]=aux[i].bank
            }
            aux2.sort((a, b) => a.name.localeCompare(b.name));
            setOpcionesBancos(aux2);
            setSyncfyOrganizations(aux3)
        });
    }

    const preEdit = () => {
        if(currencyEdit === mainCurrency) {
            updateAccount();
        }else {
            setModalWarning(true)
        }   
    }

    const handleOpcionBancoChange = (value) => {
        setOpcionBanco(value);
        setCurrentOrganization(syncfyOrganizations[value.name])
    }

    const handleOpcionRSChange = ( value ) => {
        setOpcionesRS( value );
    }

    const handleTipoBanco = (value) => {
        if(value.name === Idioma.cuentasYSaldos.debito[language]){
            setBankType(value);
            setBankTypeReal('D');
        }else {
            setBankType(value);
            setBankTypeReal('C');
        }

    }

    const handleTipoBancoModify = (value) => {
        if(value.name === Idioma.cuentasYSaldos.debito[language]){
            setBankTypeModify(value);
            setBanktypeRealModify('D');
        }else {
            setBankTypeModify(value);
            setBanktypeRealModify('C');
        }

    }

    const handleIngresos = ( value ) => {
        setIngresosType(value)
    }
    
    const handleEgresos = (value) => {
        setEgresoType(value);
    }

    const handleMonedaChange = (value) => {
        setMoneda(value)
    }

    const formateDate = ( date, from = 2 ) => {
        if(date === '') {
            return '---'
        }
        
        let offset;

        if(from === 1) {
            offset = 0;
        }else {
            offset = -6
        }


        const dateNow = new Date(date);

        const localDate = new Date(dateNow.getTime() + offset * 60 * 60 * 1000);

        //console.log(localDate, date);

        const year = localDate.getUTCFullYear();
        const month = localDate.getUTCMonth();
        const day = localDate.getUTCDate();

        let monthNames;

        if(language === 'en') {
            monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        }else {
            monthNames = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
        }
        let monthCurrent = monthNames[month]


        return language === 'es' ? `${day}/${monthCurrent}/${year}` : `${monthCurrent}/${day}/${year}`; 
    }

    const dateFormat2 = (date) => {
        let auxDate = new Date(Math.round((date - 25569)*1000))
        auxDate = new Date(auxDate.getTime() + auxDate.getTimezoneOffset() * 60000)
        let year = auxDate.getFullYear();
        let month = (auxDate.getMonth() + 1).toString().padStart(2, '0');
        let day = auxDate.getDate().toString().padStart(2, '0');
        return formateDate(`${year}-${month}-${day}`,1);
    }

    const createBalance = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientId: idClient
        }

        fetch(`${msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'https://cico-functions-dev.azurewebsites.net/api/CreateAllBalances' : 'https://cico-functions.azurewebsites.net/api/CreateAllBalances'}`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
    }

    const getData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            "clientID": idClient,
            "endDate": formatDatePicker(dateFilter),
            logsInfo: {
                origin: 'Cuentas y Saldos',
                sessionID: sessionID
            }
        }

        if(idRS) {
            body.idRS = idRS.toString();
        }
        else {
            body.idRS = '';
        }
        const response = await fetch(`${urlBase}/chart/getBalanceByBank`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if(!response.ok) {
            throw new Error('Network response was not ok');
        }
        if(response.status === 204) {
            setNothing(true);
        }else {
            const aux = await response.json();
            let debit = Array.isArray(aux['D']) ? aux['D'] : [];
            let credit = Array.isArray(aux['C']) ? aux['C'] : [];

            setTotalItemsCredit(credit.length);
            setTotalItemsDebit(debit.length);

            setTotalPagesCredit(Math.ceil(credit.length / itemsPerPage));
            setTotalPagesDebit(Math.ceil(debit.length / itemsPerPage));

            setState(prevState => ({
                ...prevState,
                infoDebit: debit,
                infoCredit: credit,
            }));
        }
        setReloadData(false)
    }

    const getRazonesSociales = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient,
            logsInfo: {
                origin: 'Cuentas y Saldos',
                sessionID: sessionID
            }
        }
        fetch(`${urlBase}/getRS`, {
            method: 'POST', 
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(( res ) => res.json())
        .then( ( aux ) => {
            let final = [];
            for(let i = 0; i < aux.length; i++) {
                final.push({name: aux[i].rsName, id: aux[i].idRS})
            }
            setRs(final);
        } );
    }

    const formatDateUpdate = ( date ) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}-${month}-${day}`;
        setnewDate(aux);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}-${month}-${day}`;
        setnewDate(aux);
    }
    
    const getCategories = () => {
        const headers = new Headers();

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        
        fetch(`${urlBase}/getCategoriesFinance`, {
            method: "POST", 
            headers: headers, 
            body: JSON.stringify({
                "clientID": idClient,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        })
        .then(res => res.json())
        .then(aux => {
            let inflowCategoriesData = aux['inflow'];
            let inflowName = aux['inflowName'];
            let outflowCategoriesData = aux['outflows'];
            let outflowName = aux['outflowName'];

            setInflowCategories(inflowCategoriesData)
            setInflowName(inflowName);
            setOutflowCategories(outflowCategoriesData)
            setOutFlowName(outflowName);

        })
    }

    const getExchange = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        const response = await fetch(`${urlBase}/getExchange`, {
            method: 'GET',
            headers: headers,
            body: JSON.stringify({
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }})
        })
        if(!response.ok) {
            throw new Error('Network response was not ok');
        }
        const aux = await response.json();
        let exchange = aux.exchange;
        setState(prevState => ({
            ...prevState,
            currentEx: exchange,
        }))
    }

    const convert = ( number ) => {
        if(number == 0 || number == NaN || number == 'NaN'){
            return 0;
        }
        return parseFloat( number );
    }

    const formatNumbers = (number) => {
        if(number == 0.0 || number === ' ' || number === NaN || number === null || number === undefined){
            return '--';
        }else{
            let num = parseFloat(number).toFixed(2);
            let numStr = num.toString();
    
            let [integer, decimal] = numStr.split(".");
            integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            
            numStr = decimal ? `${integer}.${decimal}` : integer;
    
            if(numStr.indexOf("-") !== -1) {
                if(numberFormat === 1) {
                    return numStr;
                }else {
                    let newFormat = numStr.replace('-', '');
                    return `(${newFormat})`;
                }
            } else {
                return numStr + ' ';
            }
        }
    }

    const sumatoryMXN = (type) => {
        let total = 0;
        if (type === 1) {
            state.infoDebit.map( (item) => {
                if(item.saldo === null || item.saldo === undefined || item.saldo === NaN || item.saldo === '') {
                    total += 0;
                }else {
                    total += convert( item.saldo )
                }
            });
        }else {
            state.infoCredit.map( (item) => {
                if(item.saldo === null || item.saldo === undefined || item.saldo === NaN || item.saldo === ''){
                    total += 0;
                }else {
                    total += convert( item.saldo )
                }
            } );
        }
        const realTotal = formatNumbers( total );
        return realTotal;
    }

    const sumatoryUSD = (type) => {
        let total = 0;
        if (type === 1) {
            state.infoDebit.map( (item) => {
                
                total += convert( item.saldoUSD )
            } );
        }else {
            state.infoCredit.map( (item) => {
                total += convert( item.saldoUSD )
            } );
        }
        const realTotal = formatNumbers( total );
        return realTotal;
    }

    const sumatoryTotalMXN = (type) => {
        let total = 0;
        if (type === 1) {
            state.infoDebit.map( (item) => {
                total += convert( item.totalMXN )
            } );
        }else {
            state.infoCredit.map( (item) => {
                total += convert( item.totalMXN )
            } );
        }

        const realTotal = formatNumbers( total );
        return realTotal;
    }

    const getNewDate = () => {
        const dateToday = new Date();
        let day = dateToday.getDate();
        let month = dateToday.getMonth();
        let year = dateToday.getFullYear();
        let format = `${moment(`${month + 1}-${day}-${year}`).locale(language).format('MMM D, YYYY')}`;
        let formatterDate = format.replace('.', '');
        formatterDate = formatterDate.charAt(0).toUpperCase() + formatterDate.slice(1);
        return formatterDate;
    }

    const createAccount = async() => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json');
        fetch(`${urlBase}/createAccount`,  {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'clientID': idClient,
                'bankID': opcionBanco['id'],
                'account': account,
                'currency': moneda['name'],
                'initialValue': initialValue,
                "date": newDate,
                "accountType": bankTypeReal, 
                "financialInflowCategory": bankTypeReal === 'D' ? null : ingresosType,
                "financialOutflowCategory": bankTypeReal === 'D' ? null :  egresoType,
                "Alias": alias,
                "isActive": 1, 
                "idRS": !idRS ?  opcionRS.id : idRS,
                "closingDay": bankTypeReal === 'D' ? null :  startDateCutoff.id,
                "syncfyID": syncfyAccount.id,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        }).then(res => {
                if(res.ok){
                    setLoadCreateAc(false);
                    setModalAddCount('success');
                    isOpenModal(true);
                    setReloadData(true);
                    createBalance()
                }else {
                    setLoadCreateAc(false);
                    setFlagErrorMessage(true);
                    setErrorMessage(Idioma.cuentasYSaldos.error[language])
                }
            })
            .catch(err => {
                console.error(err);
            });
    }  

    const verifyModal = (formData) => {
        const { opcionBanco, account, moneda, initialValue, startDate, bankType, bankTypeReal, ingresosType, outflowsType } = formData;
        if (opcionBanco.name === Idioma.cuentasYSaldos.banco[language]) return('Please select a bank.');
        if (!account) return( 'Account field must not be empty.');
        if (moneda.name === Idioma.cuentasYSaldos.moneda[language]) return( 'Please select a currency.');
        if (initialValue === null) return( 'Initial value must be greater or equal to 0.');
        if (!startDate) return( 'Please select a start date.');
        if (bankType.name === Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language]) return( 'Please select a bank type.');
        if (!bankTypeReal) return( 'Bank type real must not be empty.');
        return '';
    }

    const verifyDeuplicates = ( cuenta, type ) => {
        console.log(cuenta, type)
        let result = true;
        console.log(result, 1)

        if( type.name === Idioma.cuentasYSaldos.debito[language]){
            state.infoDebit.forEach( element => {
                if( element.account === cuenta && element.account !== validDuplicateNames[0]) {
                    console.log(result, 2)
                    setFlagErrorMessage(true);
                    result = false;
                }
            } )
        }else {
            state.infoCredit.forEach( element => {
                if( element.account === cuenta && element.account !== validDuplicateNames[0]) {
                    console.log('here');
                    console.log(result, 3)
                    setFlagErrorMessage(true);
                    result = false;
                }
            } )
        }
        if( result === false ) {
            setErrorMessage(Idioma.cuentasYSaldos.error2[language  ]);
            return false;
        }
        return true;
    } 
    
    const handleSubmit = () => {
        setLoadCreateAc(true);
        const formData = {
            opcionBanco: opcionBanco,
            account: account,
            moneda: moneda,
            initialValue: initialValue,
            startDate: startDate,
            bankType: bankType,
            bankTypeReal: bankTypeReal,
            closingDay: startDateCutoff.id
        };

        let notRs = false;

        if(rs.length > 0 && opcionRS.name === Idioma.cuentasYSaldos.razonSocial[language] && !idRS) {
            notRs = true;
            setErrorMessage(Idioma.cuentasYSaldos.errorRazonSocial[language])
        }

        let duplicated = verifyDeuplicates(formData.account, formData.bankType);
        let ErrorMessage;
        ErrorMessage = verifyModal(formData);

        if(!duplicated) {
            ErrorMessage = Idioma.cuentasYSaldos.duplicado[language];
        }

        if( !ErrorMessage && duplicated && !notRs ){
            setDisableButton(true);
            createAccount();
        }
        else {
            setLoadCreateAc(false);
            setFlagErrorMessage(true);
        }
    }

    const handleChangeBankUpload = (value) => {
        setOpcionBank(value);
    }

    const formatDateUpload = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}-${month}-${day}`;

        setNewDateUpload(aux);
    }

    const formatDateUploadCheck = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let aux = `${year}${month}${day}`;

        setNewDateUpload(aux);
    }


    const formatterPreVal = ( value ) => {
        let result = [];
        for(let i = 0; i < value.length; i++) {
            if(value[i] !== ',') {
                result.push(value[i]);
            }
        }
        return parseFloat(result.join().replace(/,/g, ''));
    } 

    const updateAccount = () => {
        setLoadUpdateAc(true);
        let headers = new Headers();
        let body = {
            'clientID': parseInt(idClient),
            'accountID': idUpdate,
            //'bankID': opcionBank['id'], 
            'account': defaultAccount,
            'accountAlias': accountAlias,
            //'currency': monedaUpload['name'],
            'initialValue': formatterPreVal(currentValue),
            'date': newDateUpload,
            //'accountType': bankTypeReal,
            'financingInflowCategory': bankType.name === Idioma.cuentasYSaldos.debito[language] ? null : ingresosType,
            'financingOutflowCategory': bankType.name === Idioma.cuentasYSaldos.debito[language] ? null : egresoType,
            'isActive': isActivate === true ? 1 : 0,
            'rsID': !idRS ? rsModify.id : idRS,
            "closingDay": bankType.name === Idioma.cuentasYSaldos.debito[language] ? null :  startDateCutoff.id,
            "syncfyAccountID": syncfyAccount.id ? syncfyAccount.id : '0',
            logsInfo: {
                origin: 'Cuentas y Saldos',
                sessionID: sessionID
            }
        }
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        fetch(`${urlBase}/updateAccount`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify(body)
        }).then(response => {
            if(!response.ok){
                throw new Error('Network response was no ok')
            }
            return response.json();
        })
        .then(aux => {
            setReloadData(true);
            setTimeout(() => {
                setOptionsModal(false);
                setLoadUpdateAc(false);
            }, 1500);
        }).catch((err) => {
            setLoadUpdateAc(false);
            console.log(err);
        });
    }

    const verifyIfActive = ( account, type ) => {
        if(type === 'D'){
            const item = state.infoDebit.find((item) => item.id === account);
            return item ? item.isActive : undefined;
        }else {
            const item = state.infoCredit.find((item) => item.id === account);
            return item ? item.isActive : undefined;
        }
    }

    const toggle = (event) => {
        const newState = event.target.checked;
        setIsActivate(newState);
    };

    const setInfloOutflowCategory = ( id ) => {
        const item = state.infoCredit.find( item => item.id === id);
        setIngresosType(item.FInflowsCategory);
        setEgresoType(item.FOutflowsCategory);
    }
    

    const getBankName = ( bank ) => {
        setBankTitle( bank );
    }

    const handleCurrentSyncfy = (org, idSyncfy) => {
        let findAccount = allSyncfyAccounts.filter(x => x.id==idSyncfy)
        if(idSyncfy && findAccount.length>0){
            setSyncfyAccount({name:findAccount[0].name, id:idSyncfy, org_id:org,})
        }else{
            setSyncfyAccount({name:Idioma.Syncfy.noVinculada[language]})
        }
        setCurrentOrganization(org)
    }

    const  getPrevValues = (currency, id, type, bank, alias, total) => {
        setCurrencyEdit(currency);
        const currencyMap = {
            'MXN': accountDataMXN,
            'USD': accountDataUSD,
            'EUR': accountDataEUR
        };

        const accountData = currencyMap[currency];

        if(!accountData) return;

        accountData.forEach((item) => {
            if(item.id === id) {
                handleCommonTasks( item, id, type, bank,alias );
            }
        });

        setOptionsModal(true);
    }

    const handleCommonTasks = ( item, id, type, bank, alias ) => {
        let cleanValue = parseFloat(item.initialValue.replace(/,/g, ''));
        handleChangeBankUpload({name: bank});
        setAccountId(id);
        getBankName(bank);
        handleCurrentSyncfy(syncfyOrganizations[bank], item.idSyncfy)
        setDefaultAccount(item.account);
        setAccountAlias(alias);
        setValidDuplicateNames([item.account,alias])
        setMonedaUpload({name: item.currency});
        setIsActivate(verifyIfActive(item.id, type));
        setCurrentValue(cleanValue.toFixed(2));
        
        let initialDate = new Date(item.initialDate);
        initialDate.setDate(initialDate.getDate() + 1); // Ajustar un día
        setDateUpload(initialDate);
        setIdUpdate(item.id);
        setClosingDay(item.closingDay);
        setStartDateCutoff({name: item.closingDay, id: item.closingDay});
        
        if (type === 'D') {
            setBankTypeModify({name: Idioma.cuentasYSaldos.debito[language]});
            setBanktypeRealModify('D');
        } else {
            setBankTypeModify({name: Idioma.cuentasYSaldos.credito[language]});
            setBanktypeRealModify('C');
            setInfloOutflowCategory(item.id);
        }
    
        for (let i = 0; i < rs.length; i++) {
            if (item.rs === rs[i].id && item.rs) {
                setRsModify({name: rs[i].name, id: rs[i].id});
            } else if (item.rs == null) {
                break;
            }
        }
    }

    const handleDeleteAccounts = async() => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        fetch(`${urlBase}/deleteAccount`, {
            method: 'POST', 
            headers: headers, 
            body: JSON.stringify({
                "clientID": idClient,
                "accountID": itemToDelete,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        }).then(res => {
            setReloadData(true);
            setOptionsModal(false);
            setItemToDelete('');
            setDeleteConfirmation(false);
        });
    }

    const handleOptionsModal = () => {
        setOptionsModal(false)
        resetValues();
    }

    const handleAddModal = () => {
        setOpenAgregar(false)
        resetValues();
    }

    const verifyAccount = async( idAccount ) => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
        const response = await fetch( `${urlBase}/verifyAccount`, {
            method: 'POST',
            headers: headers, 
            body: JSON.stringify( {
                "clientID": idClient,
                "idAccount": idAccount,
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            } )
        } )
        if( !response.ok ) {
            throw new Error('Network response was no ok');
        }

        const aux = await response.json();

        if(aux === true) {
            setItemToDelete( idAccount );
        }

        setCanDelete(aux);

    }

    useEffect(()=> {
        if(dateUpload){
            disabledButton()
        }
    }, [dateUpload]);

    const disabledButton = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
        const response = await fetch(`${urlBase}/confirmDate`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "clientID": idClient,
                "date_d": formatDateUploadCheck(dateUpload),
                logsInfo: {
                    origin: 'Cuentas y Saldos',
                    sessionID: sessionID
                }
            })
        });
        let res  = await response.json()
        setDisableButtonModify(!res.res);
    }

    if(openAgregar){
        document.body.classList.add('overflow-y-hidden')
    }else{
        document.body.classList.remove('overflow-y-hidden')
    }

    const handleOnEnter = (value, index) => {
        if (parseInt(value) < 0) {
            setErrorIndex(index);  // Almacenar el índice de la fila con error
        } else {
            setErrorIndex(null);   // Limpiar el índice si el valor no es negativo
        }
    };
    
    const handleOnLeave = () => {
        setErrorIndex(null);  // Limpiar el índice cuando el mouse sale
    };

    const filteredData = searchTerm === '' ? (activeTab === 'credit' ? state.infoCredit : state.infoDebit) : (activeTab === 'credit' ? state.infoCredit : state.infoDebit).filter((item) => {
        return(
            item.rsName?.toLowerCase().includes(searchTerm.toLowerCase()) || 
            item.Bank?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.account?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.alias?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.currency?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    })

    return (
        <div className="bg-total-white 2xl:mt-12">
            {state.infoCredit.length === 0 && state.infoDebit.length === 0 && !nothing ?
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'></div>
                    <div className="w-full h-full flex justify-center items-center pr-[15%]">
                        <Player
                            className="w-[20%]"
                            autoplay
                            loop
                            src={Loading}
                        ></Player>
                    </div>
                </div>
            :
            null
            }
            {openAgregar && 
                <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={() => handleAddModal()} className='fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6'></div>
                    <div className="bg-white z-10 absolute top-[50%] left-[35%] max-w-[487px] min-w-[487px] rounded-main -translate-x-[50%] -translate-y-[50%] shadow-high-elevation">
                        {loadCreateAc ?
                            <Player
                                className="w-[15%]"
                                autoplay
                                loop
                                src={Loading}
                            ></Player>
                            : null
                        }
                        <div className="flex justify-between items-start">
                            <div className="flex flex-wrap mt-[40px] ml-[40px]">
                                <h2 className="text-h5-2xl">{ Idioma.cuentasYSaldos.agregarCuentas[language]}</h2>
                            </div>
                            <button onClick={() => handleAddModal()} className="mt-[20px] mr-[20px]">
                                <XMark />
                            </button>
                        </div>
                        {modalAddCount === 'success' && openModal &&
                            <MessageToast type="success" title={Idioma.cuentasYSaldos.tituloExito[language]} message={Idioma.cuentasYSaldos.mensajeExito[language]} setSign={isOpenModal}/>
                        } 
                        <div className="flex flex-col mt-[30px]">
                            <div className="flex justify-between items-center">
                                <h3 className="text-p6-2xl text-center ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.banco[language]}</h3>
                                <Dropdown selected={opcionBanco} setSelected={handleOpcionBancoChange} options={opcionesBancos} className={opcionBanco.name === Idioma.cuentasYSaldos.banco[language] ? 'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' :'bg-white border-1  w-[270px] border-neutral-1 text-neutral-1 rounded-button mr-[40px] text-p9-2xl' }/>
                            </div>
                            {rs.length > 0 && !idRS &&
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.empresa[language]}</h3>
                                    <Dropdown selected={opcionRS} setSelected={handleOpcionRSChange} options={rs} className={opcionRS.name === Idioma.cuentasYSaldos.razonSocial[language]  ? 'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' :'bg-white border-1 border-neutral-1 w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl'}/>
                                </div>
                            }
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.cuenta[language]}</h3>
                                <input type="text" onChange={(e) => setAccount(e.target.value)} value={account} className={account.length > 0 || account === undefined ? 'bg-white w-[270px] rounded-button border-1 border-neutral-1 pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-neutral-2 text-p9-2xl':'bg-white w-[270px] rounded-button border-1 border-neutral-1 pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-neutral-2 text-p9-2xl'} placeholder={Idioma.cuentasYSaldos.agregarCuentasModal.placeholder.account[language]}/>
                            </div>
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.alias[language]}</h3>
                                <input type="text" onChange={(e) => setAlias(e.target.value)} value={alias} className={alias.length > 0 || alias === undefined ? 'bg-white w-[270px] rounded-button border-1 border-neutral- pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-neutral-2 text-p9-2xl':'bg-white w-[270px] rounded-button border-1 border-neutral-1 pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-neutral-2 text-p9-2xl'} placeholder={Idioma.cuentasYSaldos.agregarCuentasModal.placeholder.aliasDeLaCuenta[language]}/>
                            </div>
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.moneda[language]}</h3>
                                <Dropdown selected={moneda} setSelected={handleMonedaChange} options={monedas} className={moneda.name === Idioma.cuentasYSaldos.moneda[language]  ? 'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' : 'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' }/>
                            </div>
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.valorInicial[language]}</h3>
                                <input type='number' onChange={(e) => setInitialValue(e.target.value)} value={initialValue} className={`${(initialValue < 0 && bankTypeReal !== 'C') ? 'border-red-600' : 'border-neutral-1'} bg-white w-[270px] rounded-button border-1  py-2 mr-[40px] pl-5 text-p9-2xl focus:bg-none`}/>
                            </div>
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.fechaInicial[language]}</h3>
                                <div className="w-[270px] mr-[40px] h-full">
                                    <DatePicker selected={startDate} className="bg-white py-2 rounded-button border-1 border-neutral-1 pl-5 text-p9-2xl" onChange={(date) => {
                                        formatDate(date);
                                        setStartDate(date);
                                    }}                                        
                                    locale={language}
                                    dateFormat={Idioma.formatoFecha[language]}                               
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight/>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-[15px]">
                                <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language]}</h3>
                                <Dropdown selected={bankType} setSelected={handleTipoBanco} options={typeBank} className={bankType.name === Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language] ? 'bg-white border-1 border-neutral-1  w-[270px] h-[32px] text-neutral-1 rounded-button text-p9-2xl mr-[40px]' : 'bg-white border-1 border-neutral-1 w-[270px] h-[32px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl'}/>
                            </div>
                            {bankTypeReal === 'C' ? 
                            <>
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.agregarCuentasModal.fechaCorte[language]}</h3>
                                    <Dropdown className={bankType.name === Idioma.cuentasYSaldos.agregarCuentasModal.tipoCuenta[language] ? 'bg-white border-1 border-neutral-1  w-[270px] h-[32px] text-neutral-1 rounded-button text-p9-2xl mr-[40px]' : 'bg-white border-1 border-neutral-1 w-[270px] h-[32px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl'} selected={startDateCutoff} setSelected={setStartDateCutoff} options={Array.apply(null, {length: 28}).map(function(value, index){return {name: index + 1, id: index + 1}})}/>
                                </div>
                                </>
                            : null}
                            {userAccess.access7 && !idRS && <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl ml-[40px]">{Idioma.Syncfy.vincular[language]}</h3>
                                    <DinamicDropdown
                                        options={syncfyAccountOptions}
                                        selected={syncfyAccount}
                                        setSelected={setSyncfyAccount}
                                        className={`w-[270px] text-p9-2xl h-[32px] mr-[40px]`}
                                        disabled={syncfyAccountOptions.length<1}
                                    />
                            </div>}
                        </div>
                        <div className="flex flex-wrap w-[60%] justify-between items-center mt-10 ml-auto mb-[40px] mr-[40px]">
                            <button className="rounded-button bg-purple-1 w-[140px] h-9" onClick={resetValues}>
                                <p className="2xl:text-h11-2xl text-white">{Idioma.cuentasYSaldos.agregarCuentasModal.reiniciarValores[language]}</p>
                            </button>
                            <button className={`${disableButton ? 'bg-light-purple-4' : 'bg-light-purple-3'} w-[140px] rounded-button h-9`} onClick={handleSubmit} disabled={disableButton}>
                                <h2 className="2xl:text-h11-2xl">{Idioma.cuentasYSaldos.agregarCuentasModal.agregarCuentas[language]}</h2>
                            </button>
                        </div>
                    </div>
                </div>
            }
            {optionsModal &&
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div onClick={() => handleOptionsModal()} className="fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6"></div>
                    <div className="bg-neutral-6 z-10 absolute top-[40%] left-[40%] max-w-[442px] min-w-[442px] mt-14 rounded-main -translate-x-[50%] -translate-y-[50%] shadow-high-elevation">
                        <div className="flex justify-between">
                            <h2 className="mt-[40px] ml-[40px] text-h5-2xl">{bankTitle}</h2>
                            <div className="mr-[20px] mt-[20px] cursor-pointer z-50" onClick={() => setOptionsModal(false)}>
                                <XMark />
                            </div>
                        </div>
                        <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl">{Idioma.cuentasYSaldos.modificarPreModal.numeroDeCuenta[language]}</h3>
                            <p className="w-[40%] text-left">{defaultAccount}</p>
                        </div>
                        <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.moneda[language]}</h3>
                            <p className="w-[40%] text-left">{monedaUpload.name}</p>
                        </div>
                        <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.valorInicial[language]}</h3>
                            <p className="w-[40%] text-left">{new Intl.NumberFormat('en-US', {currency: 'USD', minimumFractionDigits: 2, style: 'currency', currencySign: numberFormat === 0 ? 'accounting' : 'standard'}).format(currentValue)}</p>
                        </div>
                        <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.fechaInicial[language]}</h3>
                            <p className="w-[40%] text-left">{formateDate(dateUpload)}</p>
                        </div>
                        <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.tipoDeCuenta[language]}</h3>
                            <p className="w-[40%] text-left">{bankTypeModify.name}</p>
                        </div>
                        {/*<div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                            <h3 className="text-h10-2xl mr-[40px]">Saldo equivalente</h3>
                            <p className="w-[40%] text-left">{bankTypeModify.name}</p>
                        </div>*/}
                        {rs.length > 0 && !idRS &&
                            <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                                <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.razonSocial[language]}</h3>
                                <p className="w-[40%] text-left">{rsModify.name}</p>
                            </div>
                        }
                        { bankTypeModify.name === "Crédito" &&
                            <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                                <h3 className="text-h10-2xl mr-[40px]">{Idioma.cuentasYSaldos.modificarPreModal.fechaCorte[language]}</h3>
                                <p className="w-[40%] text-left">{closingDay}</p>
                            </div>
                        }
                        {userAccess.access7 && !idRS && <div className="mt-[40px] ml-[40px] w-[80%] flex flex-wrap justify-between">
                                    <h3 className="text-h10-2xl">{Idioma.Syncfy.vincular[language]}</h3>
                                    <p className="w-[40%] text-left">{syncfyAccount.name}</p>
                            </div>}
                        <div className="w-full my-[40px] flex justify-between px-[40px]">
                            <button className="w-[25%] flex justify-between items-center" onClick={() => {
                                setEditPostModal(() => !editPostModal)
                                setOptionsModal(false)
                            }}>
                                <Pencil />
                                <p>{Idioma.cuentasYSaldos.editar[language]}</p>
                            </button>
                           {(syncfyAccount.id && userAccess.access7 && !idRS) && <button className="text-neutral-1 text-h12-2xl text-center border-1 border-neutral-1 rounded-main w-[125px] h-[35px]" onClick={() => setSyncfyDeleteConfirmation(true)}>
                                {Idioma.Syncfy.eliminarCuenta[language]}
                            </button>}
                            <button className="text-neutral-1 text-h12-2xl text-center border-1 border-neutral-1 rounded-main w-[125px] h-[35px]" onClick={() => {
                                verifyAccount(accountId);
                                setDeleteConfirmation(true);
                            }}>
                                {Idioma.cuentasYSaldos.eliminar[language]}
                            </button>
                        </div>
                    </div>
                </div>
            }
            {editPostModal &&
                <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                        <div onClick={() => setEditPostModal(false)} className='fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6'></div>
                        <div className='bg-white z-10 absolute top-[50%] left-[35%] max-w-[487px] min-w-[487px] rounded-main -translate-x-[50%] -translate-y-[50%] shadow-2xl'>
                        {loadUpdateAc ?
                            <Player
                                className="w-[15%]"
                                autoplay
                                loop
                                src={Loading}
                            ></Player>
                            : null
                        }
                            <div className='flex justify-between'>
                                <div className='flex flex-wrap ml-[40px] mt-[40px]'>
                                    <h2 className='font-bold text-2xl'>{Idioma.cuentasYSaldos.modificarCuentaModal.modificarCuentas[language]}</h2>
                                </div>
                                <div>
                                    <button className="mt-[20px] mr-[20px]" onClick={() => {
                                        setEditPostModal(false)
                                    }}>
                                        <XMark />
                                    </button>
                                </div>
                            </div>
                            <div>
                                {/*<div className="flex justify-between items-center mt-[40px]">
                                    <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">Banco</h3>
                                    <Dropdown selected={opcionBank} setSelected={handleChangeBankUpload} options={opcionesBancos} className={opcionBanco.name === null ? 'bg-white border-1 border-neutral-1 w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' :'bg-secondary-light border-1  w-[270px] text-neutral-1 border-neutral-1 rounded-button mr-[40px] text-p9-2xl' }/>
                                </div>*/}
                                {rs.length > 0 && !idRS &&
                                    <div className="flex justify-between items-center mt-[15px]">
                                        <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.empresa[language]}</h3>
                                        <Dropdown selected={rsModify && rsModify !== '' ? rsModify : {name: Idioma.cuentasYSaldos.razonSocial[language]}} setSelected={setRsModify} options={rs} className={(rsModify === '' || (rsModify && rsModify.name === Idioma.cuentasYSaldos.razonSocial[language]))  ? 'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' :'bg-white border-1 border-neutral-1  w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl'}/>
                                    </div>
                                }
                                
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.cuenta[language]}</h3>
                                    <input type="text" onChange={(e) => setDefaultAccount(e.target.value)} value={defaultAccount} className={'bg-white w-[270px] rounded-button border-1 border-neutral-1 pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-md-gray text-p9-2xl'} placeholder='Nombre de la cuenta'/>
                                </div>
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.alias[language]}</h3>
                                    <input type="text" onChange={(e) => setAccountAlias(e.target.value)} value={accountAlias} className={'bg-white w-[270px] rounded-button border-1 border-neutral-1 pl-5 py-2 mr-[40px] text-neutral-1 placeholder:text-md-gray text-p9-2xl'} placeholder="Alias de la cuenta"/>
                                </div>
                                {/*<div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl neutral-1 ml-[40px]">Moneda</h3>
                                    <Dropdown selected={monedaUpload} setSelected={handleMonedaOption} options={monedas} className={moneda.length === 0 ? 'bg-white border-1 border-neutral-1 w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' : 'bg-white border-1 border-neutral-1 w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl' }/>
                            </div>*/}
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.valorInicial[language]}</h3>
                                    <input type='number' onChange={(e) => setCurrentValue(e.target.value)} value={currentValue} className='bg-white w-[270px] rounded-button border-1 border-neutral-1 py-2 mr-[40px] pl-5 text-p9-2xl'/>
                                </div>
                                <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl ml-[40px] text-neutral-1">{Idioma.cuentasYSaldos.modificarCuentaModal.fechaInicial[language]}</h3>
                                    <div className="w-[270px] mr-[40px]">
                                        <DatePicker selected={dateUpload} className="bg-white py-2 rounded-button border-1 border-neutral-1 pl-5 text-p9-2xl" onChange={(date) => {
                                            formatDateUpload(date);
                                            setDateUpload(date);
                                        }}                                        
                                        locale={language}
                                        dateFormat={Idioma.formatoFecha[language]}                         
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight/>
                                    </div>
                                </div> 
                                {/*<div className="flex justify-between mt-[15px] items-center">
                                    <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">Tipo de cuenta</h3>
                                    <Dropdown selected={bankTypeModify} setSelected={handleTipoBancoModify} options={typeBank} className={'bg-white border-1 border-neutral-1 w-[270px] text-neutral-1 rounded-button mr-[40px] text-p9-2xl'}/>
                                    </div>*/}
                                {/* {bankTypeRealModify === 'C' && 
                                <>
                                    <div className="flex justify-between items-center mt-[15px]">
                                        <h3 className="text-p6-2xl ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.fechaCorte[language]}</h3>
                                        <DinamicDropdown className={'w-[270px] text-p9-2xl h-[32px] mr-[40px]'} selected={startDateCutoff} setSelected={setStartDateCutoff} options={Array.apply(null, {length: 28}).map(function(value, index){return {name: index + 1, id: index + 1}})}/>
                                    </div>
                                    <div className="mt-[22px]">
                                        <h2 className="text-h10-2xl ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.flujoFinanciamiento[language]}</h2>
                                        <div className="flex mt-[15px] items-center justify-between">
                                            <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.ingreso[language]}</h3>
                                            <select
                                                value={ingresosType}
                                                className="bg-white rounded-button w-[270px] border-1 border-neutral-1 h-9 pl-5 mr-[40px] text-p9-2xl"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleIngresos(value);
                                                }}
                                            >
                                                <option value="" disabled>Ingresos de Financiamiento</option>
                                                <optgroup label={inflowName}>
                                                    {inflowCategories.map((element) => (
                                                        <option value={element['idElement']}>{element.name}</option>
                                                    ))}
                                                </optgroup>
                                            </select>
                                        </div>
                                        <div className="flex justify-between items-center mt-[15px]">
                                            <h3 className="text-p6-2xl ml-[40px] text-neutral">{Idioma.cuentasYSaldos.modificarCuentaModal.egreso[language]}</h3>
                                            <select
                                                value={egresoType} // El valor actual seleccionado
                                                className="bg-white rounded-button w-[270px] border-1 border-neutral-1 h-9 pl-5 mr-[40px] text-p9-2xl"
                                                onChange={(e) => {
                                                    const id = e.target.value;
                                                    handleEgresos(id);
                                                }}
                                            >
                                                <option value="" disabled>Egresos de Financiamiento</option>
                                                <optgroup label={outflowName}>
                                                    {outflowCategories.map((element) => (
                                                        <option value={element['idElement']}>{element.name}</option>
                                                    ))}
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    </>
                                } */}
                                <div className="flex flex-nowrap items-center mt-[15px]">
                                    <div style={{width: '150px'}}>
                                        {isActivate ? 
                                                <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.activado[language]}</h3>
                                            :
                                                <h3 className="text-p6-2xl text-neutral-1 ml-[40px]">{Idioma.cuentasYSaldos.modificarCuentaModal.desactivado[language]}</h3>
                                        }
                                    </div>
                                    <label for="default-toggle1" class="inline-flex relative items-center cursor-pointer ml-6">
                                        <input type="checkbox" checked={isActivate} onClick={toggle}  id="default-toggle1" class="sr-only peer"/>
                                        <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all"></div>
                                    </label>
                                </div>
                            </div>
                            {userAccess.access7 && !idRS && <div className="flex justify-between items-center mt-[15px]">
                                    <h3 className="text-p6-2xl ml-[40px]">{Idioma.Syncfy.vincular[language]}</h3>
                                    <DinamicDropdown
                                        options={syncfyAccountOptions}
                                        selected={syncfyAccount}
                                        setSelected={setSyncfyAccount}
                                        className={`w-[270px] text-p9-2xl h-[32px] mr-[40px]`}
                                        disabled={syncfyAccountOptions.length<1}
                                    />
                            </div>}
                            <div className="w-full flex justify-end mt-[40px]">
                                <button className={`${flagErrorMessageUpdate ? 'bg-ligth-md-purple' : 'bg-buttons-saldos'} rounded-button w-[160px] h-[32px] mr-[40px] mb-[40px]`} onClick={preEdit} disabled={flagErrorMessageUpdate}>
                                    <p className='text-total-white font-bold'>{Idioma.cuentasYSaldos.modificarCuentaModal.actualizarDatos[language]}</p>
                                </button>
                            </div>
                        </div>
                        {modalWarning && (
                            <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1001] h-screen">
                                <div onClick={() => setModalWarning(false)} className='fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6'></div>
                                <div className="flex flex-col justify-center bg-neutral-6 z-10 absolute top-[40%] p-9 min-h-[212px] max-h-[212px] left-[40%] max-w-[527px] min-w-[527px] mt-14 rounded-main -translate-x-[50%] -translate-y-[50%] shadow-high-elevation">
                                    <h2 className="text-center text-5xl text-red-500 font-bold">{Idioma.cuentasYSaldos.Cuidado[language]}</h2>
                                    <h3 className="text-center text-base font-bold">{Idioma.cuentasYSaldos.mensajeAdvertencia[language]}</h3>
                                    <div className="flex justify-between mx-10 mt-10">
                                        <button onClick={() => setModalWarning(false)} className="w-[30%] h-12 rounded-button bg-red-600 text-white font-bold">{language === 'es' ? 'Cancelar': 'Cancel'}</button>
                                        <button onClick={() => updateAccount()} className="w-[30%] h-12 rounded-button bg-purple-3 text-white font-bold">{language === 'es' ? 'Aceptar' : 'Accept'}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            }
            {DeleteConfirmation &&
                <>
                        {canDelete === true ? 
                            <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1001] h-screen">
                                <div onClick={() => setDeleteConfirmation(false)} className="fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6"></div>
                                <div className="flex flex-col justify-center bg-neutral-6 z-10 absolute top-[40%] p-9 min-h-[212px] max-h-[212px] left-[40%] max-w-[527px] min-w-[527px] mt-14 rounded-main -translate-x-[50%] -translate-y-[50%] shadow-high-elevation">
                                    <h3 className='text-center text-h7-2xl'>{Idioma.cuentasYSaldos.deleteModal.estaSeguro[language]}</h3>
                                    <p className="text-center text-b2-2xl mt-[9px]">{Idioma.cuentasYSaldos.deleteModal.advertenciaBorrar[language]}</p>
                                    <p className="text-center text-b2-2xl">{Idioma.cuentasYSaldos.deleteModal.seguroContinuar[language]}</p>
                                    <div className='flex items-center mx-auto px-10 mt-5'>
                                        <button className='w-[100px] ml-5 text-neutral-1 underline rounded-xl h-[32px] text-b4-2xl' onClick={() => setDeleteConfirmation(false)}>
                                            {Idioma.cuentasYSaldos.deleteModal.cancelar[language]}
                                        </button>
                                        <button className='w-[100px] bg-md-blue text-md-white border-md-blue rounded-main h-[32px] text-b4-2xl' onClick={ () => {handleDeleteAccounts()} }>
                                            {Idioma.cuentasYSaldos.deleteModal.confirmar[language]}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        
                        : canDelete === false ? 
                            <div className='fixed top-0 right-0 bottom-0 w-screen z-[1000] h-screen'>
                                <div className="absolute bottom-0 right-0 p-5">
                                    <MessageToast type={"error"} title={Idioma.cuentasYSaldos.deleteModal.errorAlBorrarCuenta[language]} message={Idioma.cuentasYSaldos.deleteModal.noSePuedeBorrar[language]} setSign={setDeleteConfirmation} />
                                </div>
                            </div>
                        : null}

                </>
            }
            {SyncfyDeleteConfirmation &&
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1001] h-screen">
                <div onClick={() => setSyncfyDeleteConfirmation(false)} className="fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6"></div>
                <div className="flex flex-col justify-center bg-neutral-6 z-10 absolute top-[40%] p-9 min-h-[212px] max-h-[212px] left-[40%] max-w-[527px] min-w-[527px] mt-14 rounded-main -translate-x-[50%] -translate-y-[50%] shadow-high-elevation">
                    <h3 className='text-center text-h7-2xl'>{Idioma.cuentasYSaldos.deleteModal.estaSeguro[language]}</h3>
                    <p className="text-center text-b2-2xl mt-[9px]">{Idioma.Syncfy.mensajeEliminar[language]}</p>
                    <p className="text-center text-b2-2xl">{Idioma.cuentasYSaldos.deleteModal.seguroContinuar[language]}</p>
                    <div className='flex items-center mx-auto px-10 mt-5'>
                        <button className='w-[100px] ml-5 text-neutral-1 underline rounded-xl h-[32px] text-b4-2xl' onClick={() => setSyncfyDeleteConfirmation(false)}>
                            {Idioma.cuentasYSaldos.deleteModal.cancelar[language]}
                        </button>
                        <button className='w-[100px] bg-md-blue text-md-white border-md-blue rounded-main h-[32px] text-b4-2xl' onClick={deleteSyncfyAccount}>
                            {Idioma.cuentasYSaldos.deleteModal.confirmar[language]}
                        </button>
                    </div>
                </div>
            </div>
            }
            <div className="flex flex-col pl-[70px]">
                <div className="flex">
                    <button className="bg-light-purple-3 rounded-button flex items-center w-[200px] 2xl:w-[246px] h-[52px] p-4 mr-[15px]" onClick={() => setOpenAgregar(true)}>
                        <div className='scale-50 md:scal0e-75 2xl:scale-100'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 47.527 33.026">
                            <g id="Grupo_260" data-name="Grupo 260" transform="translate(-575 -189.974)">
                                <g id="Grupo_259" data-name="Grupo 259" transform="translate(575 189.974)">
                                <g id="Grupo_258" data-name="Grupo 258" clip-path="url(#clip-path)">
                                    <path id="Trazado_615" data-name="Trazado 615" d="M10.575,31.151a1.62,1.62,0,0,1-2.281-.941q-3.982-7-7.961-14A1.566,1.566,0,0,1,1,13.765Q12.808,7.046,24.622.33A1.565,1.565,0,0,1,27.063,1q3.331,5.853,6.65,11.714a.871.871,0,0,0,.9.508c1.663-.024,3.327-.013,4.991-.008a1.567,1.567,0,0,1,1.782,1.8q0,8.11,0,16.22a1.573,1.573,0,0,1-1.8,1.788H12.807c-1.645,0-1.985-.286-2.233-1.875M1.94,17.323,27.11,2.793c-.327-.573-.611-1.091-.916-1.6a.648.648,0,0,0-.911-.263c-.16.073-.31.166-.463.253L6.613,11.54q-2.625,1.493-5.251,2.984c-.523.3-.652.6-.414,1.051.305.577.643,1.137.992,1.748m2.519,4.442c1.606,2.821,3.184,5.6,4.769,8.368a.629.629,0,0,0,.834.274c.3-.117.573-.238.57-.7-.023-3.694-.013-7.388-.014-11.083,0-.116-.023-.232-.04-.393l-6.119,3.53m15-8.655.042.07H33.015L29.634,7.234,19.456,13.11M16,25.027c.477,0,.955.005,1.432,0a.791.791,0,0,0,.855-.848q.018-1.065,0-2.13a.784.784,0,0,0-.827-.845q-1.449-.028-2.9,0a.768.768,0,0,0-.829.835q-.019,1.082,0,2.165a.766.766,0,0,0,.836.823c.477.008.954,0,1.432,0m21.057,5.744a1.556,1.556,0,0,0,1.611-.947,1.688,1.688,0,0,0-.219-1.888,1.765,1.765,0,0,0-1.8-.636,2,2,0,0,1-.689.006,1.757,1.757,0,0,0-1.8.616,1.782,1.782,0,0,0,1.355,2.914c.515,0,1.03-.041,1.545-.064" transform="translate(0 0)" fill="#27014d"/>
                                </g>
                                </g>
                                <path id="Trazado_616" data-name="Trazado 616" d="M25.231,14.616V12.144H22.842v-.852h2.389V8.808h.863v2.484h2.389v.852H26.094v2.472Z" transform="translate(593.543 187)" fill="#27014d" stroke="#27014d" stroke-width="1"/>
                            </g>
                        </svg>
                        </div>
                        <h2 className="text-h10-2xl 2xl:text-h7-2xl ml-2">{Idioma.cuentasYSaldos.agregarCuentas[language]}</h2>
                    </button>
                    <button className="bg-light-purple-1 main flex items-center w-[185px] 2xl:w-[246px] h-[52px] p-4 rounded-button mr-[15px]" onClick={() => {navigate('/balance/checker')}}>
                        <h3 className="text-white text-h10-2xl 2xl:text-h7-2xl">{Idioma.cuentasYSaldos.verificacionSaldos[language]}</h3>
                    </button>
                    {userAccess.access7 && !idRS && syncfyToken && <SyncfyWidget token={syncfyToken} updateFunc={getSyncfyAccounts}/>}
                </div>
                <div className="flex flex-row mt-5 2xl:mt-[70px]">
                    <div className="w-[19%] flex flex-row items-center">
                        <h3 className="md-black text-h11-2xl 2xl:text-h8-2xl mr-5">{Idioma.cuentasYSaldos.fecha[language]}</h3>
                        <div className="w-button-4 2xl:w-32 z-40">
                            <DatePicker 
                                onChange={(date) => setDateFilter(date)} 
                                selected={dateFilter}
                                dateFormat={Idioma.formatoFecha[language]}      
                                locale={language}
                                maxDate={new Date()}
                                className="z-50 text-center rounded-main bg-white border-2 border-black h-10"
                                showYearDropdown
                                dropdownMode="select"
                                fixedHeight
                            />
                        </div>
                    </div> 
                    <div className="flex items-center justify-center">
                        <input 
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Buscar..."
                            className="p-2 border border-gray-300 focus:border-none rounded-l-md w-full"
                        />
                        <div className="bg-light-purple-3 p-[0.50rem] rounded-r-md">
                            <SearchLogo />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 2xl:mt-10 mr-10 ml-[70px] overflow-visible">
            {version === 2 ? (
                <>                
                    <h2 className="text-h11-2xl 2xl:text-h9-2xl font-bold md-black">{Idioma.cuentasYSaldos.debito[language]}</h2>
                    <div>
                        <table className="w-full md-black mx-auto overflow-visible">
                            <thead className=" sticky top-0 z-30 h-14 rounded-lg w-full table-fixed bg-md-white mr-20 overflow-visible">
                                <tr className="pt-3 pb-3 text-left z-0 table w-full table-fixed mt-7">
                                    {rs.length > 0 &&
                                        <th className="text-h13-2xl 2xl:text-h11-2xl flex"> 
                                            <p>{Idioma.cuentasYSaldos.razonSocial[language]}</p>
                                            <InfoI texto={Idioma.cuentasYSaldos.iIcon.Razonsocial[language]} />
                                        </th>
                                    }
                                    <th className={rs.length > 0 ? "text-h13-2xl 2xl:text-h11-2xl z-0" : "text-h13-2xl 2xl:text-h11-2xl z-0"}>{Idioma.cuentasYSaldos.banco[language]}</th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl z-0 flex justify-start">{Idioma.cuentasYSaldos.cuenta[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Cuenta[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.alias[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Alias[language]} /></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.moneda[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Moneda[language]} /></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.ultimoMovimiento[language]} <InfoI texto={Idioma.cuentasYSaldos.iIcon.Ultimomovimiento[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoCuentas[language]}</span><InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.SaldoCuenta[language]}/> </th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.tipoCambio[language]}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.TipodeCambio[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoEquivalenteMXN[language]} {mainCurrency}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.Saldoequivalente[language]}/></th> 
                                </tr>
                            </thead>
                            <tbody className="text-md-casiNegro overflow-y-scroll block max-h-[100px] w-full">
                                {state.infoDebit.map((item, index) => (
                                    <tr key={index} onClick={() => getPrevValues(item.currency, item.id, 'D', item.Bank, item.alias, item.totalMXN)} className="cursor-pointer hover:bg-light-purple-5 hover:rounded-button table w-full table-fixed">
                                        {rs.length > 0 &&
                                            <td className={item.isActive ? " py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                                {item.rsName ? item.rsName : '---'}
                                            </td>
                                        }
                                        <td className={rs.length > 0 ? "py-1 flex items-center w-[167px]" : "py-1 flex items-center w-[167px]"}>
                                            <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.Bank}.png`} alt="Imagen del banco" className="h-6 text-[20px] object-scale-down"/>
                                            <p className={item.isActive ? "ml-3 text-p11-2xl 2xl:text-p9-2xl" : "ml-3 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>{item.Bank}</p>
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.account}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 ext-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.alias}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.currency}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {formateDate(item.lastMovement)}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {formatNumbers(item.saldo)}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.cambio === 1 ? '' : (item.cambio.toString().split('.')[1]?.length === 3 ? item.cambio.toString() + '0' : item.cambio)}
                                        </td> 
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl pr-[15px]" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400 pr-[15px]"}>
                                            {formatNumbers(item.totalMXN)}
                                        </td>
                                </tr>
                                ))}
                            </tbody> 
                            <br />
                            <tfoot className="md-black" style={{ position: 'sticky', bottom: '0', zIndex: '0', background: 'inherit' }}>
                                <tr className="pt-4 text-center z-[1000]">
                                    {rs.length > 0 ?
                                    <>
                                        <td className={rs.length > 0 ? "bg-neutral-5 text-left z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                    </>
                                        :
                                        <td className={rs.length > 0 ? "bg-neutral-5 text-base z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                    }
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base"></td>
                                    <td className="text-base font-bold bg-neutral-5 text-right"></td>
                                    <td className="text-base font-bold text-right bg-neutral-5">
                                    </td>
                                    <td className="relative bg-neutral-5 rounded-r-main">
                                        <div className="absolute top-0 right-0 md-black text-right text-base font-bold bg-neutral-5 pr-[15px]">{sumatoryTotalMXN(1)}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <h2 className="text-h11-2xl 2xl:text-h8-2xl font-bold md-black mt-2 2xl:mt-10">{Idioma.cuentasYSaldos.credito[language]}</h2>
                    <div>
                        <table className="w-full md-black mx-auto overflow-visible">
                        <thead className=" sticky top-0 z-30 h-14 rounded-lg w-full table-fixed bg-md-white mr-20 overflow-visible">
                                <tr className="pt-3 pb-3 text-left z-0 table w-full table-fixed mt-7">
                                    {rs.length > 0 &&
                                        <th className="text-h13-2xl 2xl:text-h11-2xl flex"> 
                                            <p className="pr-2">{Idioma.cuentasYSaldos.razonSocial[language]}</p>
                                            <InfoI texto={Idioma.cuentasYSaldos.iIcon.Razonsocial[language]} />
                                        </th>
                                    }
                                    <th className={rs.length > 0 ? "text-h13-2xl 2xl:text-h11-2xl z-0" : "text-h13-2xl 2xl:text-h11-2xl z-0"}>{Idioma.cuentasYSaldos.banco[language]}</th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl z-0 pl-1 flex justify-start">{Idioma.cuentasYSaldos.cuenta[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Cuenta[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl pl-3 flex justify-start">{Idioma.cuentasYSaldos.alias[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Alias[language]} /></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.moneda[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Moneda[language]} /></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.ultimoMovimiento[language]} <InfoI texto={Idioma.cuentasYSaldos.iIcon.Ultimomovimiento[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoCuentas[language]}</span><InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.SaldoCuenta[language]}/> </th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.tipoCambio[language]}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.TipodeCambio[language]}/></th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoEquivalenteMXN[language]} {mainCurrency}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.Saldoequivalente[language]}/></th> 
                                </tr>
                            </thead>
                            <tbody className="text-md-casiNegro overflow-y-scroll block max-h-[100px] w-full">
                                {state.infoCredit.map((item, index) => (
                                    <tr key={index} onClick={() => getPrevValues(item.currency, item.id, 'C', item.Bank, item.alias, item.totalMXN)} className="cursor-pointer hover:bg-light-purple-5 hover:rounded-button table w-full table-fixed">
                                        {rs.length > 0 &&
                                            <td className={item.isActive ? " py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                                {item.rsName ? item.rsName : '---'}
                                            </td>
                                        }
                                        <td className={rs.length > 0 ? "py-1 flex items-center w-[167px]" : "py-1 flex items-center w-[167px]"}>
                                            <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.Bank}.png`} alt="Imagen del banco" className="h-6 text-[20px] object-scale-down"/>
                                            <p className={item.isActive ? "ml-3 text-p11-2xl 2xl:text-p9-2xl" : "ml-3 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>{item.Bank}</p>
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.account}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 ext-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.alias}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.currency}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {formateDate(item.lastMovement, 1)}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {formatNumbers(item.saldo)}
                                        </td>
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                            {item.cambio === 1 ? '' : (item.cambio.toString().split('.')[1]?.length === 3 ? item.cambio.toString() + '0' : item.cambio)}
                                        </td> 
                                        <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl pr-[15px]" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400 pr-[15px]"}>
                                            {formatNumbers(item.totalMXN)}
                                        </td>
                                </tr>
                                ))}
                            </tbody> 
                            <br />
                            <tfoot className="md-black" style={{ position: 'sticky', bottom: '0', zIndex: '0', background: 'inherit' }}>
                                <tr className="pt-4 text-center z-[1000]">
                                    {rs.length > 0 ?
                                    <>
                                        <td className={rs.length > 0 ? "bg-neutral-5 text-left z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                    </>
                                        :
                                        <td className={rs.length > 0 ? "bg-neutral-5 text-base z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                    }
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base"></td>
                                    <td className="text-base font-bold bg-neutral-5 text-right"></td>
                                    <td className="text-base font-bold text-right bg-neutral-5">
                                    </td>
                                    <td className="relative bg-neutral-5 rounded-r-main">
                                        <div className="absolute top-0 right-0 md-black text-right text-base font-bold bg-neutral-5 pr-[15px]">{sumatoryTotalMXN(activeTab === 'debit' ? 1 : 3)}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-full mt-8">
                        <div className="w-full mt-8 flex flex-wrap justify-between">
                            <div className="flex border-b border-gray-300 w-[40%]">
                                <button
                                    className={`w-1/2 py-2 text-center ${activeTab === 'debit' ? 'border-b-2  border-purple-1 bg-purple-1 text-white font-bold' : 'text-black bg-gray-6 font-bold'}`}
                                    onClick={() => {
                                        setActiveTab('debit');
                                    }}
                                    >
                                    {Idioma.transacciones.debito[language]}
                                </button>
                                <button className={`w-1/2 py-2 text-center ${activeTab === 'credit' ? 'border-b-2 border-purple-1 bg-purple-1 text-white font-bold' : 'text-black bg-gray-6 font-bold'}  ${activeTab === 'linked' ? 'border-l border-gray-400 font-bold':''}`}
                                onClick={() => {
                                    setActiveTab('credit')
                                    }}>
                                    {Idioma.transacciones.credito[language]}
                                </button>
                                {userAccess.access7 && !idRS && <button className={`w-1/2 py-2 text-center ${activeTab === 'linked' ? 'border-b-2 border-purple-1 bg-purple-1 text-white font-bold' : 'text-black bg-gray-6 font-bold'} ${activeTab === 'linked' || activeTab === 'debit' ? 'border-l border-gray-400 font-bold':''}`}
                                onClick={() => {
                                    setActiveTab('linked')
                                    }}>
                                    {Idioma.Syncfy.vinculados[language]}
                                </button>}
                            </div>
                        </div>
                        <hr className="border-gray-400 border-1" />
                        <div className="p-4">
                        { activeTab === 'linked'?
                            <table className="w-full md-black mx-auto overflow-visible">
                            <thead className="sticky top-0 z-30 h-14 rounded-lg w-full table-fixed bg-md-white mr-20 overflow-visible">
                                <tr className="pt-3 pb-3 text-left z-0 table w-full table-fixed mt-7">
                                    <th className={"text-h13-2xl 2xl:text-h11-2xl z-0"}>{Idioma.cuentasYSaldos.banco[language]}</th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl z-0 flex justify-start">{Idioma.Syncfy.vinculacion[language]}</th>
                                    <th className="w-[0.002px]"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start xs:hidden xxs:hidden">{Idioma.Syncfy.cuentasVinculada[language]}</th>
                                    <th className="w-[0.002px]  xs:hidden xxs:hidden"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start  xs:hidden xxs:hidden">{Idioma.Syncfy.aliasVinculada[language]}</th>
                                    <th className="w-[0.002px]  xs:hidden xxs:hidden"></th>
                                    <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start  xs:hidden xxs:hidden">{Idioma.Syncfy.ultimaSinc[language]}</th>
                                </tr>
                            </thead>
                            <div className="max-h-[300px] overflow-y-auto">
                                <tbody className="max-h-5 overflow-y-auto">
                                    {(state.infoLinked).map((item, index) => (
                                        <tr key={index} className={`hover:bg-light-purple-5 hover:rounded-button table w-full table-fixed`}>
                                            <td className={"py-1 flex items-center w-[167px]"}>
                                                <img src={`https://cicofiles.blob.core.windows.net/bankimages/${syncfyOrganizations[item.org_id]}.png`} alt="Imagen del banco" className="h-6 text-[20px] object-scale-down"/>
                                            </td>
                                            <td className={`py-1 text-p11-2xl 2xl:text-p9-2xl`}>
                                                {item.name}
                                            </td>
                                            <td className={`py-1 text-p11-2xl 2xl:text-p9-2xl`}>
                                                {item.accountNumber}
                                            </td>
                                            <td className={`py-1 text-p11-2xl 2xl:text-p9-2xl`}>
                                                {item.accountAlias}
                                            </td>
                                            <td className={`py-1 text-p11-2xl 2xl:text-p9-2xl`}>
                                                {dateFormat2(item.last_sync)}
                                            </td>
                                            <td className="w-[1px]">
                                            </td> 
                                        </tr>
                                    ))}
                                </tbody> 
                            </div>
                            <br />
                            {/* <tfoot className="md-black" style={{ position: 'sticky', bottom: '0', zIndex: '0', background: 'inherit' }}>
                                <tr className="pt-4 text-center z-[1000]">
                                <td className="bg-neutral-5 text-base z-0 rounded-l-md font-bold h-5"></td>
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base z-0"></td>
                                    <td className="bg-neutral-5 text-base"></td>
                                    <td className="text-base font-bold bg-neutral-5 text-right"></td>
                                    <td className="text-base font-bold text-right bg-neutral-5">
                                    </td>
                                    <td className="relative bg-neutral-5 rounded-r-main">
                                    </td>
                                </tr>
                            </tfoot> */}
                        </table>
                        :
                            <table className="w-full md-black mx-auto overflow-visible">
                                <thead className="sticky top-0 z-30 h-14 rounded-lg w-full table-fixed bg-md-white mr-20 overflow-visible">
                                    <tr className="pt-3 pb-3 text-left z-0 table w-full table-fixed mt-7">
                                        {rs.length > 0 &&
                                            <th className="text-h13-2xl 2xl:text-h11-2xl flex"> 
                                                <p>{Idioma.cuentasYSaldos.razonSocial[language]}</p>
                                                <InfoI texto={Idioma.cuentasYSaldos.iIcon.Razonsocial[language]} />
                                            </th>
                                        }
                                        <th className={rs.length > 0 ? "text-h13-2xl 2xl:text-h11-2xl z-0" : "text-h13-2xl 2xl:text-h11-2xl z-0"}>{Idioma.cuentasYSaldos.banco[language]}</th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl z-0 flex justify-start">{Idioma.cuentasYSaldos.cuenta[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Cuenta[language]}/></th>
                                        <th className="w-[0.002px]"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start xs:hidden xxs:hidden">{Idioma.cuentasYSaldos.alias[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Alias[language]} /></th>
                                        <th className="w-[0.002px]  xs:hidden xxs:hidden"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start  xs:hidden xxs:hidden">{Idioma.cuentasYSaldos.moneda[language]}<InfoI texto={Idioma.cuentasYSaldos.iIcon.Moneda[language]} /></th>
                                        <th className="w-[0.002px]  xs:hidden xxs:hidden"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl flex justify-start">{Idioma.cuentasYSaldos.ultimoMovimiento[language]} <InfoI texto={Idioma.cuentasYSaldos.iIcon.Ultimomovimiento[language]}/></th>
                                        <th className="w-[0.002px]"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoCuentas[language]}</span><InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.SaldoCuenta[language]}/> </th>
                                        <th className="w-[0.002px]"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end  xs:hidden xxs:hidden"><span className="whitespace-pre">{Idioma.cuentasYSaldos.tipoCambio[language]}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.TipodeCambio[language]}/></th>
                                        <th className="w-[0.002px]  xs:hidden xxs:hidden"></th>
                                        <th className="text-h13-2xl 2xl:text-h11-2xl text-right flex pl-10 justify-end"><span className="whitespace-pre">{Idioma.cuentasYSaldos.saldoEquivalenteMXN[language]} {mainCurrency}</span> <InfoI popXDirection="left" texto={Idioma.cuentasYSaldos.iIcon.Saldoequivalente[language]}/></th> 
                                    </tr>
                                </thead>
                                <div className="max-h-[300px] overflow-y-auto">
                                    <tbody className="max-h-5 overflow-y-auto">
                                        {filteredData.map((item, index) => (
                                            <tr key={index} onMouseEnter={() => handleOnEnter(item.totalMXN, index)} onMouseLeave={() => handleOnEnter()} onClick={() => getPrevValues(item.currency, item.id, activeTab === 'debit' ? 'D' : 'C', item.Bank, item.alias, item.totalMXN)} className={`cursor-pointer hover:bg-light-purple-5 hover:rounded-button table w-full table-fixed ${parseInt(formatNumbers(item.totalMXN)) < 0 && activeTab !==  'credit' ? 'text-red-700 font-bold' : null}`}>
                                                {rs.length > 0 &&
                                                    <td className={`${item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"} truncate xs:truncate xxs:truncate`}>
                                                        {item.rsName ? item.rsName : '---'}
                                                    </td>
                                                }
                                                <td className={rs.length > 0 ? "py-1 flex items-center w-[167px]" : "py-1 flex items-center w-[167px]"}>
                                                    <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.Bank}.png`} alt="Imagen del banco" className="h-6 text-[20px] object-scale-down"/>
                                                </td>
                                                <td className={item.isActive ? `py-1 text-p11-2xl 2xl:text-p9-2xl` : `py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400`}>
                                                    {item.account}
                                                </td>
                                                <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl xs:hidden xxs:hidden" : "py-1 ext-p11-2xl 2xl:text-p9-2xl text-gray-400 xs:hidden xxs:hidden"}>
                                                    {item.alias}
                                                </td>
                                                <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl xs:hidden xxs:hidden" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400 xs:hidden xxs:hidden"}>
                                                    {item.currency}
                                                </td>
                                                <td className={item.isActive ? "py-1 text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                                    {formateDate(item.lastMovement)}
                                                </td>
                                                <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400"}>
                                                    {formatNumbers(item.saldo)}
                                                </td>
                                                <td className={item.isActive ? "py-1 text-right text-p11-2xl 2xl:text-p9-2xl xs:hidden xxs:hidden" : "py-1 text-right text-p11-2xl 2xl:text-p9-2xl text-gray-400 xs:hidden xxs:hidden"}>
                                                    {item.cambio === 1 ? '' : (item.cambio.toString().split('.')[1]?.length === 3 ? item.cambio.toString() + '0' : item.cambio)}
                                                </td>
                                                <td className="w-[1px]">
                                                </td> 
                                                <td   className={item.isActive 
                                                    ? `py-1 text-right text-p11-2xl 2xl:text-p9-2xl pr-[15px] ${parseInt(formatNumbers(item.totalMXN)) < 0 && activeTab !==  'credit' ? 'text-red-700 font-bold' : 'text-black'}`
                                                    : ` py-1 text-right text-p11-2xl 2xl:text-p9-2xl pr-[15px] text-gray-400 ${parseInt(formatNumbers(item.totalMXN)) < 0 && activeTab !== 'credit' ? 'text-red-700' : ''}`}>
                                                        <div className="flex items-end justify-end text-right">
                                                        { parseInt(formatNumbers(item.totalMXN)) < 0 && activeTab !== 'credit' && (
                                                            <InfoI texto={Idioma.cuentasYSaldos.cuentasNegativas[language]} popDirection={index === 0 ? false : true} popXDirection={"left"} />
                                                        )}
                                                        <span className="ml-2">
                                                        {formatNumbers(item.totalMXN)}
                                                        </span>
                                                        </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> 
                                </div>
                                <br />
                                <tfoot className="md-black" style={{ position: 'sticky', bottom: '0', zIndex: '0', background: 'inherit' }}>
                                    <tr className="pt-4 text-center z-[1000]">
                                        {rs.length > 0 ?
                                        <>
                                            <td className={rs.length > 0 ? "bg-neutral-5 text-left z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                        </>
                                            :
                                            <td className={rs.length > 0 ? "bg-neutral-5 text-base z-0 font-bold" : "bg-neutral-5 text-base z-0 rounded-l-md font-bold" }>Total</td>
                                        }
                                        <td className="bg-neutral-5 text-base z-0"></td>
                                        <td className="bg-neutral-5 text-base z-0"></td>
                                        <td className="bg-neutral-5 text-base"></td>
                                        <td className="text-base font-bold bg-neutral-5 text-right"></td>
                                        <td className="text-base font-bold text-right bg-neutral-5">
                                        </td>
                                        <td className="relative bg-neutral-5 rounded-r-main">
                                            <div className="absolute top-0 right-0 md-black text-right text-base font-bold bg-neutral-5 pr-[15px]">{sumatoryTotalMXN(activeTab === 'debit' ? 1 : 3)}</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>}
                        </div>
                    </div>
                </>
            )}
            <div className="fixed bottom-5 right-5 z-[10000]">
                {syncfyWarning &&
                    <MessageToast type={"success"} title={Idioma.Syncfy.advertenciaDesvincular[language]} message={`${Idioma.Syncfy.cuentasDesvinculadas1[language]}${unlinkedAccounts}${Idioma.Syncfy.cuentasDesvinculadas2[language]}`} setSign={setSyncfyWarning} />
                }
            </div>
            </div>
        </div>

    );
}

export default Saldos;