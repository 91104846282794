import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { useLocation } from 'react-router-dom';
import { GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { useStateContext } from '../contexts/ContextProvider';
import { DashboardFilters} from '../components';
import Idioma from '../components/Idioma'
import fiscalWeeks from "../components/fiscalWeek";
import { urlBase } from "../authConfig";
import formatterDates3Letters from '../utils/datesRefactor';
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';
import { useNavigate } from "react-router-dom";

echarts.use([LineChart, GridComponent, CanvasRenderer, UniversalTransition]);

const ChartLineSubPage = () => {
    const location = useLocation();
    let divider = location.state?.divider;
    let dates = location.state?.dates
    let data = location.state?.data
    let type = location.state?.type;
    const [chartData, setChartData] = useState({
        dates: dates,
        divider: divider,
        data: data,
        type: type
    });
    const { accessToken, idClient, idRS, language, mainCurrency, numberFormat, userAccess, sessionID } = useStateContext();
    const [nothing, setNothing] = useState(false);
    const [rs, setRs] = useState([]); 
    const isAuthenticated = useIsAuthenticated();
    const [showlabels, setShowLabels] = useState();
    const navigate = useNavigate(); 

    const [state, setState] = useState({
        infoDebit: [],
        infoCredit: [],
        currentEx: undefined,
        date: '',
    });
    const [requestParams, setRequestParams] = useState([null,null,null,null,null]);

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})
    }, []);

    const fechas = {
        'es': {
            'enero': 1,
            'febrero': 2,
            'marzo': 3,
            'abril': 4,
            'mayo': 5,
            'junio': 6,
            'julio': 7,
            'agosto': 8,
            'septiembre': 9,
            'octubre': 10,
            'noviembre': 11,
            'diciembre': 12
        },
        'en': {
            'january': 1,
            'february': 2,
            'march': 3,
            'april': 4,
            'may': 5,
            'june': 6,
            'july': 7,
            'august': 8,
            'september': 9,
            'october': 10,
            'november': 11,
            'december': 12
        }
    };

    useEffect(() => {
        if(type === 'D' && sessionStorage.getItem('showLabels1')){
            setShowLabels(sessionStorage.getItem('showLabels1'))
        }else if(type !== 'D' && sessionStorage.getItem('showLabels2')){
            setShowLabels(sessionStorage.getItem('showLabels2'))
        }
    },[]);

    const dateFormatter = ( date ) => {
        let fechaDividida
        if(language === 'es') {
            fechaDividida = date.split(" ");
            return `${parseInt(fechaDividida[1]) < 10 ? "0" + fechaDividida[1] : fechaDividida[1]}/${fechas[language][fechaDividida[3]]}`
        }
        fechaDividida = date.split(" ");
        return `${fechas[language][fechaDividida[1]]}/ ${parseInt(fechaDividida[2]) < 10 ? "0" + fechaDividida[2] : fechaDividida[2]}`
    }

    useEffect(() => {
        if(sessionStorage.getItem('creditCharts') && sessionStorage.getItem('debitCharts')) {
            let credit =JSON.parse(sessionStorage.getItem('creditCharts'));
            let debit = JSON.parse(sessionStorage.getItem('debitCharts'));

            setState({
                ...state,
                infoDebit: debit,
                infoCredit: credit,
            });
        }
    }, []);

    useEffect( () => {
        if(requestParams[1] != null || requestParams[1] != undefined) {
            //getChartLineData();
            getChartLineDataExample();
            getData();
        }
    } , [requestParams]);

    const getChartLineDataExample = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            startDate: requestParams[1],
            endDate: requestParams[2],
            groupType: requestParams[3],
            language: language,
            logsInfo: {
                origin: type === 'D' ? 'Resumen - Saldo en bancos' : 'Resumen - Saldo tarjetas de credito',
                sessionID: sessionID
            }
        }

        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4]) {
            body.idRS = requestParams[4].toString();
        }else {
            body.idRS = '';
        }

        const response = await fetch(`${urlBase}/chart/customeFilterChart`, {
            method: 'POST', 
            headers: headers,
            body: JSON.stringify(body)
        })

        if(!response.ok) {
            throw new Error('Network response was no ok');
        }

        if(response.status === 200){
            const aux = await response.json();
            if(requestParams[3] === 2) {
                let nuevoWeekArray = [];
                for(let i = 0; i < aux.dayListLabels.length; i++){
                    let fullWeekInfo = aux.dayListLabels[i].split(" ");
                    let year = fullWeekInfo[1];
                    let numberWeek = fullWeekInfo[0].split("S")[1];
                    nuevoWeekArray.push(dateFormatter(fiscalWeeks[year][numberWeek][language]));
                }
                aux.realDate = nuevoWeekArray;
            }

            setChartData({
                ...chartData,
                dates: aux.dayListLabels,
                realDate: aux.realDate,
                divider: aux.dividerSign,
                realData: chartData.type === 'D' ? aux.realDebit : aux.realCredit,
                data: chartData.type === 'D' ? aux.thisDebit : aux.thisCredits
            });
        }
    }

    const getChartLineData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
  
        let body = {
            clientID: idClient,
            startDate: requestParams[1], 
            endDate: requestParams[2],
            groupType: 3, 
            categoryGroup: requestParams[0], 
            logsInfo: {
                origin: type === 'D' ? 'Resumen - Saldo en bancos' : 'Resumen - Saldo tarjetas de credito',
                sessionID: sessionID
            }
        };
  
        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4]) {
            body.idRS = requestParams[4].toString();
        }else {
            body.idRS = '';
        }
  
        const response = await fetch(`${urlBase}/chart/downSideCharts`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
  
        if(!response.ok) {
            throw new Error('Network response was no ok');
        }
  
        if(response.status === 200) {
            const aux = await response.json()

            setChartData({
                ...chartData,
                dates: aux.dayListLabels,
                divider: aux.divider,
                data: chartData.type === 'D' ? aux.thisDebit : aux.thisCredits
            });
        }
    }

    const getData = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            "clientID": idClient,
            "endDate": requestParams[2],
            logsInfo: {
                origin: type === 'D' ? 'Resumen - Saldo en bancos' : 'Resumen - Saldo tarjetas de credito',
                sessionID: sessionID
            } 
        }
        if(idRS) {
            body.idRS = idRS.toString();
        }else if(requestParams[4]) {
            body.idRS = requestParams[4].toString();
        }else {
            body.idRS = '';
        }
        const response = await fetch(`${urlBase}/chart/getBalanceByBank`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        if(!response.ok) {
            throw new Error('Network response was not ok');
        }

        if(response.status === 204) {
            setNothing(true);
        }else {
            const aux = await response.json();
            let debit = Array.isArray(aux['D']) ? aux['D'] : [];
            let credit = Array.isArray(aux['C']) ? aux['C'] : [];

            setState(prevState => ({
                ...prevState,
                infoDebit: debit,
                infoCredit: credit,
            }));

            sessionStorage.setItem('creditCharts', JSON.stringify(credit));
            sessionStorage.setItem('debitCharts', JSON.stringify(debit));
        }
    }

    const formateDate = ( date ) => {
        if(date === '') {
            return '---'
        }
        const dateNow = new Date(date);

        const year = dateNow.getUTCFullYear();

        const month = dateNow.getUTCMonth();

        const day = dateNow.getUTCDate();

        let monthNames;

        if(language === 'en') {
            monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        }else {
            monthNames = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
        }
        let monthCurrent = monthNames[month]

        return language === 'es' ? `${day}/${monthCurrent}/${year}` : `${monthCurrent}/${day}/${year}`; 
    }

    const formatNumbers = (number) => {
        if(number == 0.0 || number === ' ' || number === NaN || number === null || number === undefined){
            return '--';
        }else{
            let num = parseFloat(number).toFixed(2);
            let numStr = num.toString();
    
            let [integer, decimal] = numStr.split(".");
            integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            
            numStr = decimal ? `${integer}.${decimal}` : integer;
    
            if(numStr.indexOf("-") !== -1) {
                if(numberFormat === 1) {
                    return numStr;
                }else {
                    let newFormat = numStr.replace('-', '');
                    return `(${newFormat})`;
                }
            } else {
                return numStr + ' ';
            }
        }
    }

    const getRazonesSociales = async() => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body = {
            clientID: idClient, 
            logsInfo: {
                origin: type === 'D' ? 'Resumen - Saldo en bancos' : 'Resumen - Saldo tarjetas de credito',
                sessionID: sessionID
            }
        }
        fetch(`${urlBase}/getRS`, {
            method: 'POST', 
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(( res ) => res.json())
        .then( ( aux ) => {
            let final = [];
            for(let i = 0; i < aux.length; i++) {
                final.push({name: aux[i].rsName, id: aux[i].idRS})
            }
            setRs(final);
        } );
    }

        let xAxisData = chartData && requestParams[3] === 2 ? chartData.realDate : (chartData ? chartData.dates : []);

    let option = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        tooltip:{
            formatter: (params) => {
                let index = params.dataIndex;

                if( typeof index !== 'number' ) {
                    index = chartData.dates.findIndex(month => month.startsWith(index));
                }

                let month = (typeof index === 'number' && chartData.dates[index]) ? chartData.dates[index] : 'Indice no valido';

                let result;
                if( requestParams[3] === 2 ) {
                    let newValues = month.split(" ");
                    let week = newValues[0].split('S')[1];
                    result = formatterDates3Letters(fiscalWeeks[newValues[1]][week][language], language) + " " + newValues[1] + '<br/>';
                }else {
                    result = `${month} <br/>`;
                }
                let amount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD', 
                currencySign: numberFormat === 0 ? 'accounting' : 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
                }).format(chartData.realData[index]);
                let valueWithoutSign = amount.replace(/\$/g, '');
                result += `${params.marker} ${valueWithoutSign}`;
                return result;
            }
        },
        grid: {
            left: 0,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: chartData.data,
              type: 'line',
              label: {
                show: showlabels,
                position: 'top',
                formatter: (params) => new Intl.NumberFormat('en-US', {maximumFractionDigits: 1, minimumFractionDigits: 1}).format(params.value)
              }
            }
          ],
          color: '#8829FF'
    }

    const toggle = (event) => {
        const newState = event.target.checked;
        setShowLabels(newState);
        sessionStorage.setItem('showLabels1', newState);
    };

    return (
        <div className="w-full flex flex-col ml-[5%]">
            <div className='flex flex-wrap items-start'>
                <DashboardFilters setRequestParams={setRequestParams} innerGraph={true} flowFilter={false}/>
                <div className='flex ml-5 items-center'>
            <div>
                <label for="default-toggle1" class="inline-flex mt-1 relative items-center cursor-pointer ml-6">
                    <input type="checkbox" checked={showlabels} onClick={toggle}  id="default-toggle1" class="sr-only peer"/>
                    <div class="2xl:w-11 w-8 2xl:h-6 h-4 rounded-full peer bg-md-white border-tertiary peer-checked:border-md-white border-1 peer-checked:bg-tertiary  peer-checked:after:translate-x-full after:content-[''] after:absolute 2xl:peer-checked:after:top-[4px] peer-checked:after:top-[2px] 2xl:peer-checked:after:left-[8px] peer-checked:after:left-[5px] 2xl:after:top-[4px] after:top-[2px] 2xl:after:left-[4px] after:left-[2px] after:bg-tertiary peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full 2xl:after:h-4 after:h-3 2xl:after:w-4 after:w-3 after:transition-all"></div>
                </label>
            </div>
            <div className='w-40 ml-5'>
              {showlabels 
              ? (
                <h2>{Idioma.resumen.desactivarLabels[language]}</h2>
              ) 
              : (
                <h2>{Idioma.resumen.activarLabels[language]}</h2>
              )
              }
            </div>
          </div>
            </div> 
            {type === 'D' ? 
                <h3 className='text-h8-2xl'>{Idioma.resumen.graficasAbajo.evolucionBalanceBancos[language]}</h3>
            :
                <h3 className='text-h8-2xl'>{Idioma.resumen.graficasAbajo.evolucionSaldosTarjetasCredito[language]}</h3>
            }
            <div className='w-[100%] mt-[15px] ml-20'>
                <ReactEChartsCore 
                    echarts={echarts}
                    option={option}
                    notMerge
                    lazyUpdate
                    theme={"theme_name"}
                    style={{ height: '400px', width: '75%' }}
                />
            </div>
            {idClient && userAccess &&  
                <>
                    {userAccess.access4 &&                   
                        <div>
                            <div className="w-[95%] flex justify-between flex-col">
                                <h2 className="text-xl text-h6-2xl">{Idioma.resumen.graficasAbajo.cuentas[language]}</h2>
                                <div className='xxs:overflow-x-auto mobileL:overflow-x-auto mobileM:overflow-x-auto mobileS:overflow-x-auto thin-scrollbar'>
                                    <table className="w-[90%] xl:w-[95%] lg:w-[95%] md:w-[95%] sm:w-[95%] xs:w-full xxs:w-full md-black">
                                        <thead className=" sticky top-0 z-30 h-14 rounded-lg w-full bg-md-white mr-20">
                                            <tr className="pt-3 pb-3 text-left z-0">
                                                {rs.length > 0 &&
                                                    <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold">{Idioma.cuentasYSaldos.razonSocial[language]}</th>
                                                }
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold z-0 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.banco[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold z-0 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.cuenta[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.alias[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis xxs:pr-[15px] mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.moneda[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold whitespace-pre xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis xxs:pr-[15px] mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.ultimoMovimiento[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold whitespace-pre text-right xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.saldoCuentas[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold whitespace-pre text-right pl-6 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.tipoCambio[language]}</th>
                                                <th className="text-[10px] md:text-[12px] 2xl:text-p8-2xl 2xl:font-bold whitespace-pre text-right pl-6 pr-[15px] xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis">{Idioma.cuentasYSaldos.saldoEquivalenteMXN[language]} {mainCurrency}</th> 
                                            </tr>
                                        </thead>
                                        <tbody className="text-md-casiNegro">
                                            {type === 'D' ?
                                                state.infoDebit.map((item, index) => (
                                                    <tr key={index} className="hover:bg-light-purple-5 hover:rounded-button">
                                                        {rs.length > 0 &&
                                                            <td className={item.isActive ? "pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                                {item.rsName ? item.rsName : '---'}
                                                            </td>
                                                        }
                                                        <td className="pt-2 flex items-center">
                                                            <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.Bank}.png`} alt="Imagen del banco" className="h-6 text-[7px] object-scale-down"/>
                                                            <p className={item.isActive ? "ml-3 text-[8px] md:text-[10px] 2xl:text-p9-2xl xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "ml-3 text-[8px] md:text-[10px] 2xl:text-p9-2xl text-gray-400 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>{item.Bank}</p>
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {item.account}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {item.alias}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pr-5 ml-9 pt-2 text-[6px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {item.currency}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pr-5  ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {formateDate(item.lastMovement)}
                                                        </td>
                                                        <td className={item.isActive ? "text-right text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "text-right text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {formatNumbers(item.saldo)}
                                                        </td>
                                                        <td className={item.isActive ? "ml-6 text-right pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "ml-6 text-right pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {item.cambio === 1 ? '' : (item.cambio.toString().split('.')[1]?.length === 3 ? item.cambio.toString() + '0' : item.cambio)}
                                                        </td> 
                                                        <td className={item.isActive ? "ml-6 text-right  pr-[15px] pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xlxxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "ml-6 text-right  pr-[15px] pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {formatNumbers(item.totalMXN)}
                                                        </td>
                                                    </tr>
                                                ))
                                            : 
                                                state.infoCredit.map((item, index) => (
                                                    <tr key={index} className="hover:bg-light-purple-5 hover:rounded-button">
                                                        {rs.length > 0 &&
                                                            <td className={item.isActive ? "pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                                {item.rsName ? item.rsName : '---'}
                                                            </td>
                                                        }
                                                        <td className="pt-2 flex items-center">
                                                            <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.Bank}.png`} alt="Imagen del banco" className="h-6 text-[7px] object-scale-down"/>
                                                            <p className={item.isActive ? "ml-3 text-[8px] md:text-[10px] 2xl:text-p9-2xl xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "ml-3 text-[8px] md:text-[10px] 2xl:text-p9-2xl text-gray-400 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>{item.Bank}</p>
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis" : "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400 xxs:overflow-hidden xxs:whitespace-nowrap xxs:text-ellipsis mobileL:overflow-hidden mobileL:whitespace-nowrap mobileL:text-ellipsis mobileM:overflow-hidden mobileM:whitespace-nowrap mobileM:text-ellipsis mobileS:overflow-hidden mobileS:whitespace-nowrap mobileS:text-ellipsis"}>
                                                            {item.account}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "pr-5 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {item.alias}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "pr-5 ml-9 pt-2 text-[6px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {item.currency}
                                                        </td>
                                                        <td className={item.isActive ? "pr-5 ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "pr-5  ml-9 pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {formateDate(item.lastMovement)}
                                                        </td>
                                                        <td className={item.isActive ? "text-right text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "text-right text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {formatNumbers(item.saldo)}
                                                        </td>
                                                        <td className={item.isActive ? "ml-6 text-right pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "ml-6 text-right pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {item.cambio === 1 ? '' : (item.cambio.toString().split('.')[1]?.length === 3 ? item.cambio.toString() + '0' : item.cambio)}
                                                        </td> 
                                                        <td className={item.isActive ? "ml-6 text-right  pr-[15px] pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl" : "ml-6 text-right  pr-[15px] pt-2 text-[8px] md:text-[10px] l:text-[12px] 2xl:text-p9-2xl text-gray-400"}>
                                                            {formatNumbers(item.totalMXN)}
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody> 
                                    </table>
                                </div>
                            </div>
                        </div>
                    }                
                </>
            }
        </div>
    );
}

export default ChartLineSubPage;